import * as Yup from 'yup';

import {AddCustomerToBidFormValues} from 'pages/Bids/types';
import {QUOTE_SOURCE} from 'pages/Bids/constants';

const validation = Yup.object<AddCustomerToBidFormValues>({
    quoteSource: Yup.string().required('Please select quotes source'),
    quoteSourceAnother: Yup.string().when('quoteSource', ([quoteSource], schema) => {
        return quoteSource === QUOTE_SOURCE.another.code ? schema.required('Please enter source') : schema.optional();
    }),
    customer: Yup.object().required('Please select customer'),
});

export default validation;
