import React, {ChangeEvent, useEffect, useState} from 'react';
import {useField, FieldHookConfig} from 'formik';
import Input from 'antd/lib/input';
import Item from 'antd/lib/form/FormItem';

import {FormInputField} from 'pages/Bids/components/ConfirmedLines/types';
import {getNormalCityLine} from 'pages/Bids/utils';

import {convertAddressToInputValue, updateInputValue} from './utils';

type OwnProps = Omit<FormInputField, 'inputNode'> & FieldHookConfig<string> & {placeholder: string};

const AddressInput: React.FC<OwnProps> = (props) => {
    const [value, setValue] = useState<string>();
    const [field, meta, helpers] = useField<string>(props);
    const {maxLength, placeholder} = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const {value: inputValue} = event.target;
        const cityLine = getNormalCityLine(inputValue);
        const updatedInputValue = updateInputValue(cityLine);
        helpers.setValue(updatedInputValue);
        setValue(inputValue);
    };

    useEffect(() => {
        setValue(convertAddressToInputValue(field.value));
    }, []);
    return (
        <Item help={meta.error} validateStatus={meta.error ? 'error' : ''}>
            <Input
                value={value}
                onBlur={field.onBlur}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={handleChange}
            />
        </Item>
    );
};

export default AddressInput;
