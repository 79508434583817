import React from 'react';
import Button from 'antd/lib/button';

import useNotificationsWidgetActions from 'hooks/useFindBidByIdActions';

type OwnProps = {
    bidId: string;
};

const OpenBidButton: React.FC<OwnProps> = ({bidId}) => {
    const {receivedBidById} = useNotificationsWidgetActions();

    return (
        <>
            <Button type="link" style={{padding: 0}} onClick={() => receivedBidById(bidId)}>
                Show the Bid
            </Button>
        </>
    );
};

export default OpenBidButton;
