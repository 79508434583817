import React, {useState} from 'react';
import {Modal} from 'antd';
import Form from 'antd/lib/form';
import {Field, Formik} from 'formik';
import {GroupOutlined} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

import NoteFormTextArea from 'components/ui/form/NoteFormTextArea';

import {Customer, CustomerFormValues} from 'core/entities/Customer/types';
import {COMPANY_STATUS} from 'core/entities/Customer/constants';

import useCustomersActions from 'pages/Customers/hooks/useCustomersActions';
import {TEXT_FIELD_LENGTH} from 'pages/Customers/constants';
import {cityStateZipCountryToString} from 'pages/Customers/utils';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import ModalFooter from './components/ModalFooter';
import validation from './validation';
import AccountInformation from './components/AccountInformation';
import BillingAddress from './components/BillingAddress';
import AddedByInformation from './components/AddedByInformation';
import styles from './addCustomer.module.scss';

type OwnProps = {
    data?: Customer;
    onSubmit: (values: CustomerFormValues) => void;
    onClose: () => void;
};

const getName = getTypeFieldNameFactory<CustomerFormValues>();

const AddCustomerModal: React.FC<OwnProps> = (props) => {
    const {onClose, onSubmit, data} = props;

    const isUpdateCustomer = !isEmpty(data);

    const [loading, setLoading] = useState<boolean>(false);
    const {updateCustomer} = useCustomersActions();

    const location = data?.billingAddress ? cityStateZipCountryToString(data.billingAddress) : undefined;

    const formInitialValues: CustomerFormValues = {
        companyName: data?.companyName || '',
        status: data?.status || COMPANY_STATUS.needToVerify.code,
        mark: data?.mark,
        mcNumber: data?.mcNumber,
        dotNumber: data?.dotNumber,
        address: data?.billingAddress?.addressLine,
        location,
        note: data?.note,
    };

    const handleOk = (values: CustomerFormValues): void => {
        setLoading(true);

        if (isUpdateCustomer) {
            updateCustomer(values, data.id);
            onClose();
            return;
        }

        onSubmit(values);
        onClose();
    };

    return (
        <Formik
            initialValues={formInitialValues}
            onSubmit={handleOk}
            validationSchema={validation}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({handleSubmit}) => {
                return (
                    <Modal
                        className={styles.modal}
                        visible={true}
                        closable={true}
                        zIndex={3000}
                        onCancel={() => onClose()}
                        title={
                            <>
                                <GroupOutlined style={{color: '#1677FF'}} />{' '}
                                {isUpdateCustomer ? 'Update' : 'Create New'} Customer
                            </>
                        }
                        onOk={() => handleSubmit()}
                        footer={<ModalFooter loading={loading} isUpdate={isUpdateCustomer} />}
                    >
                        <Form>
                            {isUpdateCustomer ? <AddedByInformation customer={data} /> : null}
                            <AccountInformation isUpdate={isUpdateCustomer} />
                            <BillingAddress />
                            <Field
                                name={getName('note')}
                                type="text"
                                label="Note:"
                                placeholder="Your note"
                                maxLength={TEXT_FIELD_LENGTH}
                                as={NoteFormTextArea}
                            />
                        </Form>
                    </Modal>
                );
            }}
        </Formik>
    );
};

export default AddCustomerModal;
