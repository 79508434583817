import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';
import PopConfirm from 'antd/lib/popconfirm';

import {initialCreateBidValues} from 'pages/Bids/data';
import {CreateBidFormValues} from 'pages/Bids/types';

import {isCreateBidValuesAsInitial} from '../../utils';

import styles from './modalFooter.module.scss';

type OwnProps = {
    loading: boolean;
    isCancelButton?: boolean;
    handleCancel: (resetForm: () => void) => void;
};

const ModalFooter: React.FC<OwnProps> = ({loading, handleCancel, isCancelButton = true}) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const {handleSubmit, setValues, resetForm, values, setErrors} = useFormikContext();
    useEffect(() => {
        setIsDisabled(isCreateBidValuesAsInitial(values as CreateBidFormValues));
    }, [values]);

    const handleCancelEvent = (): void => {
        if (isDisabled) {
            handleCancel(resetForm);
        }
    };

    const handleClear = (): void => {
        setValues(initialCreateBidValues);
        setErrors({
            pickupCityLine: undefined,
            pickupAmount: undefined,
            deliveryCityLine: undefined,
            deliveryAmount: undefined,
            rate: undefined,
        });
    };
    return (
        <div className={styles.wrapper}>
            <Space>
                {isCancelButton ? (
                    <PopConfirm disabled={isDisabled} title="Sure to cancel?" onConfirm={() => handleCancel(resetForm)}>
                        <Button onClick={handleCancelEvent} key="back">
                            Cancel
                        </Button>
                    </PopConfirm>
                ) : null}

                <PopConfirm disabled={isDisabled} title="Sure to clear fields?" onConfirm={handleClear}>
                    <Button disabled={isDisabled}>Clear Fields</Button>
                </PopConfirm>
            </Space>
            <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => handleSubmit()}
                className="secondary-button"
            >
                Create Bid
            </Button>
        </div>
    );
};

export default ModalFooter;
