import * as Yup from 'yup';

import {minPasswordLength} from 'constants/index';

const minNamesLength = 2;

const validation = (isPassword: boolean): Yup.ObjectSchema<any> => {
    const passwordValidate = {
        password: Yup.string()
            .min(minPasswordLength, `password must contain at least ${minPasswordLength} characters`)
            .matches(/^(?=.*[a-z])(?=.*)(?=.*\d)[a-zA-Z\d]{8,16}$/, 'password must contain letters and numbers')
            .required('enter password'),
        confirmPassword: Yup.mixed().test('match', "confirmation password doesn't match\t", function (): boolean {
            return this.parent.password === this.parent.confirmPassword;
        }),
    };

    const passwordIsUpdate = {
        password: Yup.string()
            .min(minPasswordLength, `password must contain at least ${minPasswordLength} characters`)
            .matches(/^(?=.*[a-z])(?=.*)(?=.*\d)[a-zA-Z\d]{8,16}$/, 'password must contain letters and numbers'),
        confirmPassword: Yup.mixed().test('match', "confirmation password doesn't match\t", function (): boolean {
            return this.parent.password === this.parent.confirmPassword;
        }),
    };
    const validateObject = {
        userCode: Yup.string()
            .required('enter user code')
            // .matches(/(?=.*\d)(?=.*)[a-zA-Z\d]{1,5}$/, 'user code must contain numbers')
            .matches(/[1-9]{1,2}|[a-zA-Z]{1,5}|[1-9][0-9]{1,5}$/, 'user code can\'t be "0", try another'),
        teamCode: Yup.string()
            .required('enter team code')
            // .matches(/(?=.*\d)(?=.*)[a-zA-Z\d]{1,5}$/, 'team code must contain numbers')
            // .matches(/[1-9]{1,2}|(?=.*\d)(?=.*)[a-zA-Z\d]{1,5}$/, 'team code can\'t be "0", try another'),
            .matches(/[1-9]{1,2}|[a-zA-Z]{1,9999}|[1-9][0-9]{1,9999}$/, `team code can't be "0", try another`),
        firstName: Yup.string()
            .strict(false)
            .trim()
            .required('enter first name')
            .min(minNamesLength, 'must be more 2 digits'),
        lastName: Yup.string()
            .strict(false)
            .trim()
            .required('enter last name')
            .min(minNamesLength, 'must be more 2 digits'),
        email: Yup.string().email('enter correct email address').required('enter email'),
        company: Yup.string()
            .required('enter company name')
            .trim()
            .matches(/(?=.*[a-z])/, 'company name must contain letters, please retype it'),
        ...(isPassword ? passwordIsUpdate : passwordValidate),
    };

    return Yup.object(validateObject);
};

export default validation;
