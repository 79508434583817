import {Customer, CustomerFormValues} from 'core/entities/Customer/types';

import {showErrorModal} from 'store/actions/modalActions';

import * as requests from './requests';

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

export const createCustomer = (values: CustomerFormValues) => {
    return function (dispatch: any) {
        return new Promise((resolve, reject) => {
            requests
                .createCustomer(values)
                .then(resolve)
                .catch((error) => {
                    dispatch(showErrorModal());
                    reject(error);
                });
        });
    };
};

export const fetchCustomerItems = (value: string, callback: (data: {value: string; label: string}[]) => void) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    const searchCustomer = async () => {
        try {
            const response = await requests.getCustomerToSearch(value, {currentPage: 1, pageSize: 10});
            if (currentValue === value) {
                const data = response.data.items.map((item: Customer) => ({
                    value: item.id,
                    label: item.companyName,
                    mark: item?.mark,
                }));
                callback(data);
            }
        } catch (error) {
            console.error('Error fetching customer data:', error);
            callback([]);
        }
    };

    if (value) {
        // eslint-disable-next-line no-magic-numbers
        timeout = setTimeout(searchCustomer, 300);
    } else {
        callback([]);
    }
};
