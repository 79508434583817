import {modalNames} from '../../constants';

import CreateUserModal from './CreateUserModal';
import DeleteUserModal from './DeleteUserModal';

const modalMap = {
    [modalNames.createUser]: CreateUserModal,
    [modalNames.deleteUser]: DeleteUserModal,
};

export default modalMap;
