import io from 'socket.io-client';

import {UserData} from 'core/entities/User/types';
import {Notification} from 'core/entities/Notification/types';
import {Bid, BidCustomerInfo} from 'core/entities/Bid/types';
import {Customer, SpecialMarkType} from 'core/entities/Customer/types';

import {getCurrentUser} from 'store/reducers/userData/selectors';

import {AppState} from '../../types';

import * as types from './actionTypes';

let currentSocket: any = null;

export function init() {
    return {
        type: types.WEB_SOCKET_INIT,
    };
}

export function reconnect() {
    return {
        type: types.WEB_SOCKET_RECONNECT,
    };
}

export function connected({socket}: {socket: any}) {
    return {
        type: types.WEB_SOCKET_CONNECTED,
        payload: {socketID: socket.id},
    };
}

export function disconnected() {
    return {
        type: types.WEB_SOCKET_DISCONNECTED,
    };
}

export function connectToWebSocket() {
    return function (dispatch: any, getState: () => AppState) {
        const isDev = process.env.NODE_ENV === 'development';
        const socketUrl = isDev ? `ws://localhost:9000` : `${window.location.hostname}`;

        const userData: UserData | undefined = getCurrentUser(getState());
        // const accessToken = getUserAccessToken(getState());
        const accessToken = '12345';

        return new Promise((resolve, reject) => {
            if (currentSocket && currentSocket.connected) {
                //eslint-disable-next-line
                console.log('WebSocket already connected, reusing existing connection.');
                resolve(currentSocket);
                return;
            }

            const socket = io(socketUrl, {
                query: {
                    userId: userData?.id,
                },
                transports: ['websocket'],
                path: isDev ? '' : '/api/socket.io',
                reconnection: false,
            });

            currentSocket = socket;

            socket.on('connect', () => {
                // eslint-disable-next-line
                console.log('client success connected to rates websocket, socket.id = ', socket.id);

                socket.emit('authenticate', {accessToken});

                dispatch(connected({socket}));

                resolve(socket);
            });

            socket.on('disconnect', (reason: any) => {
                console.warn('WebSocket disconnected, reason: ', reason);

                dispatch(disconnected());
                currentSocket = null;

                const RECONNECT_TIMEOUT = 5000;
                setTimeout(() => {
                    if (!currentSocket) {
                        // eslint-disable-next-line
                        console.log('Attempting to reconnect WebSocket...');
                        dispatch(connectToWebSocket());
                    }
                }, RECONNECT_TIMEOUT);

                reject(reason);
            });

            socket.on('connect_error', (error: any) => {
                console.error('WebSocket connection error: ', error);
                reject(error);
            });
        });
    };
}

export function receivedCreatedBid(newBid: Bid) {
    return function (dispatch: any, getState: () => AppState) {
        const userData: UserData | undefined = getCurrentUser(getState());
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CREATED_BID,
            payload: {
                newBid,
                currentUser: userData,
            },
        });
    };
}

export function receivedAddCustomerInfoToBid({bidID, customerInfo}: {bidID: string; customerInfo: BidCustomerInfo}) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_ADD_CUSTOMER_INFO_TO_BID,
            payload: {
                bidID,
                customerInfo,
            },
        });
    };
}

export function receivedCreatedCustomer(newCustomer: Customer) {
    return function (dispatch: any, getState: () => AppState) {
        const userData: UserData | undefined = getCurrentUser(getState());
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CREATED_CUSTOMER,
            payload: {
                newCustomer,
                currentUser: userData,
            },
        });
    };
}

export function receivedCreatedBidNote(newBid: Bid) {
    return function (dispatch: any, getState: () => AppState) {
        const userData: UserData | undefined = getCurrentUser(getState());
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CREATED_BID_NOTE,
            payload: {
                newBid,
                currentUser: userData,
            },
        });
    };
}

export function receivedUpdatedBidNote(props: {
    noteID: string;
    bidID: string;
    customerInfo: {name: string; quoteSource: string; mark?: SpecialMarkType};
}) {
    return function (dispatch: any, getState: () => AppState) {
        const userData: UserData | undefined = getCurrentUser(getState());
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_UPDATE_BID_NOTE,
            payload: {
                props,
                currentUser: userData,
            },
        });
    };
}

export function receivedCreatedUser(newUser: UserData) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CREATED_USER,
            payload: {
                newUser,
            },
        });
    };
}

export function receivedRemoveUser(id: string) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_REMOVED_USER,
            payload: {
                id,
            },
        });
    };
}

export function receivedUpdateUser(updatedUser: UserData) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_UPDATED_USER,
            payload: {
                updatedUser,
            },
        });
    };
}

export function receivedCallBidNote(callBid: Bid) {
    return function (dispatch: any, getState: () => AppState) {
        const userData: UserData | undefined = getCurrentUser(getState());
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_CALL_BID,
            payload: {
                callBid,
                currentUser: userData,
            },
        });
    };
}

export function receivedNotification(notification: Notification) {
    return function (dispatch: any) {
        dispatch({
            type: types.WEB_SOCKET_RECEIVED_NOTIFICATION,
            payload: {
                notification,
            },
        });
    };
}
