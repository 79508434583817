import React from 'react';

import Select from 'components/ui/form/Select';

import {COMPANY_STATUS} from 'core/entities/Customer/constants';

const statusOptions = [
    {value: COMPANY_STATUS.needToVerify.code, label: COMPANY_STATUS.needToVerify.name},
    {value: COMPANY_STATUS.approved.code, label: COMPANY_STATUS.approved.name},
    {value: COMPANY_STATUS.archived.code, label: COMPANY_STATUS.archived.name},
];

type OwnProps = {
    name: string;
    disabled?: boolean;
};

const CompanyStatusSelect: React.FC<OwnProps> = (props) => {
    const {name, disabled} = props;

    return <Select name={name} placeholder="Company Status" options={statusOptions} disabled={disabled} />;
};

export default CompanyStatusSelect;
