import {AxiosResponse} from 'axios';

import {CustomerParams} from 'core/entities/Customer/types';

import restApi from 'services/api';

import {PaginationValues} from 'store/types';

type SearchByCustomerParams = {
    companyName?: string;
    page?: number;
    perPage?: number;
};

const getPaginationValue = (pagination: PaginationValues): {page?: number; perPage?: number} => ({
    page: pagination.currentPage,
    perPage: pagination.pageSize,
});

const getCustomerFromSearchParams = (companyName: string, pagination: PaginationValues): SearchByCustomerParams => {
    const {page, perPage} = getPaginationValue(pagination);
    return {
        companyName,
        page,
        perPage,
    };
};

export const searchCustomers = ({
    search,
    pagination,
}: {
    search: string;
    pagination: PaginationValues;
}): Promise<AxiosResponse> => {
    const url = `/customers`;
    const queryParams = {params: getCustomerFromSearchParams(search, pagination)};

    return restApi.get(url, queryParams);
};

export const createCustomer = (data: CustomerParams): Promise<AxiosResponse> => {
    const url = `/customers`;
    return restApi.post(url, data);
};

export const updateCustomer = (customer: CustomerParams, id: string): Promise<AxiosResponse> => {
    const url = `/customers/${id}`;
    return restApi.put(url, customer);
};

export const archiveCustomer = (id: string): Promise<AxiosResponse> => {
    const url = `/customers/${id}/archive`;
    return restApi.put(url);
};

export const restoreCustomer = (id: string): Promise<AxiosResponse> => {
    const url = `/customers/${id}/restore`;
    return restApi.put(url);
};

export const fetchCustomerList = (pagination: PaginationValues): Promise<AxiosResponse> => {
    const url = '/customers';
    return restApi.get(url, {
        params: getPaginationValue(pagination),
    });
};
