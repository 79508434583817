import React, {ChangeEvent, useEffect, useState} from 'react';
import {useField, FieldHookConfig} from 'formik';
import Input from 'antd/lib/input';
import Item from 'antd/lib/form/FormItem';

type OwnProps = {
    maxLength: number;
    placeholder: string;
} & FieldHookConfig<string>;

const CityLineInput: React.FC<OwnProps> = ({maxLength, placeholder, ...props}) => {
    const [field, meta, helpers] = useField<string>(props);
    const [value, setValue] = useState<string>(field.value);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const {value: inputValue} = event.target;
        helpers.setValue(inputValue);
        setValue(inputValue);
    };

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    return (
        <Item help={meta.error} validateStatus={meta.error ? 'error' : ''}>
            <Input
                value={value}
                onBlur={field.onBlur}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={handleChange}
            />
        </Item>
    );
};

export default CityLineInput;
