import React from 'react';
import Modal from 'antd/lib/modal';
import {Meta} from 'antd/lib/list/Item';
import Text from 'antd/lib/typography/Text';
import {WarningOutlined} from '@ant-design/icons';

import {getFullUserName} from '../../UsersList/utils';
import useUsersActions from '../../../hooks/useUsersActions';

import styles from './deleteUserModal.module.scss';

const DeleteUserModal = ({onClose, data}: any): JSX.Element => {
    const {deleteUser} = useUsersActions();
    const {userCode, company, id} = data;
    const userFullName = getFullUserName(data);
    const userCompany = company ? ` (${company})` : '';

    const handleOk = () => {
        deleteUser(id);
        onClose();
    };

    return (
        <Modal
            className={styles.modal}
            visible={true}
            closable={false}
            title={null}
            onOk={() => handleOk()}
            onCancel={() => onClose()}
            okText="Delete"
            okButtonProps={{danger: true}}
        >
            <Meta
                avatar={<WarningOutlined style={{fontSize: '45px', color: 'red'}} />}
                title={<div className={styles.title}>Delete User</div>}
                description={
                    <Text>
                        {userFullName} #{userCode}
                        {userCompany} will be removed from the Bid Rates Service. This operation cannot be undone
                    </Text>
                }
            />
        </Modal>
    );
};

export default DeleteUserModal;
