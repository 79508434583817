import {AxiosResponse} from 'axios';

import {getPromiseWithDelay} from 'utils/getPromiseWithDelay';

import {RECEIVED_CUSTOMER_BY_ID} from './customersActionTypes';
import * as requests from './requests';
import {showErrorModal} from './modalActions';

const DELAY_TIME = 200;

export function receivedCustomerById(customerId: string, navigate: (path: string) => void): any {
    return async function (dispatch: any): Promise<void> {
        try {
            await navigate('/customers');
            const response: AxiosResponse = await getPromiseWithDelay(DELAY_TIME).then(() =>
                requests.fetchCustomerByID(customerId),
            );
            dispatch({
                type: RECEIVED_CUSTOMER_BY_ID,
                payload: response.data.customer,
            });
        } catch (error) {
            dispatch(showErrorModal());
            console.error('Error on fetch customer by id:', error);
        }
    };
}
