import {useEffect, useState} from 'react';
import type {SelectProps} from 'antd';

import {CustomerFormValues} from 'core/entities/Customer/types';

import {fetchCustomerItems} from 'pages/Bids/actions/customerActions';

const useCustomerOptions = (customer?: CustomerFormValues) => {
    const [selectedCustomer, setSelectedCustomer] = useState<SelectProps['options']>([]);

    useEffect(() => {
        if (customer) {
            fetchCustomerItems(customer.companyName, setSelectedCustomer);
        }
    }, [customer]);

    return selectedCustomer;
};

export default useCustomerOptions;
