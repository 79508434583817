import {batch} from 'react-redux';

import {showErrorModal} from 'store/actions/modalActions';
import {AppState, PaginationValues} from 'store/types';

import {Filters} from '../types';
import * as actionTypes from '../actionTypes';
import {getUpdatedPaginationPage, isNeedFiltered} from '../utils';
import {getFilters} from '../selectors';
import {ClearFiltersParams, FiltersParamsChanged} from '../actionTypes';
import {transformFilterParamsToRequest} from '../mappers/transformFilterParamsToRequest';

import {fetchAllBids} from './bidActions';
import * as requests from './requests';

function setFilters(filtersParams: Filters): FiltersParamsChanged {
    return {
        type: actionTypes.FILTERS_PARAMS_CHANGED,
        payload: {
            filtersParams,
        },
    };
}

function clearFilters(): ClearFiltersParams {
    return {
        type: actionTypes.CLEAR_FILTERS_PARAMS,
    };
}

export function fetchFilteredBids(filtersParams: Filters, paginationParams: PaginationValues) {
    return function (dispatch: any) {
        const filters = transformFilterParamsToRequest(filtersParams);
        requests
            .fetchFilteredBids(filters, paginationParams)
            .then((filteredBids: any) => {
                batch(() => {
                    dispatch({
                        type: actionTypes.LIST_BIDS_RECEIVED,
                        payload: {
                            items: filteredBids.items,
                            pagination: filteredBids.pagination,
                        },
                    });
                    dispatch(setFilters(filters));
                });
            })
            .catch(() => {
                dispatch(showErrorModal());
            });
    };
}

export function commonFetchFilteredBids(filters: Filters) {
    return function (dispatch: any, getState: () => AppState) {
        const currentPage = 1;
        const pagination: PaginationValues = getUpdatedPaginationPage(currentPage, getState());
        if (isNeedFiltered(filters)) {
            dispatch(fetchFilteredBids(filters, pagination));
            return;
        }
        dispatch(clearFilters());
        dispatch(fetchAllBids(pagination));
    };
}

export function fetchFilteredBidsByPaginationPage(nextPage: number) {
    return function (dispatch: any, getState: () => AppState) {
        const state = getState();
        const filters = getFilters(state);
        const pagination: PaginationValues = getUpdatedPaginationPage(nextPage, getState());
        dispatch(fetchFilteredBids(filters, pagination));
    };
}
