import {Bid} from 'core/entities/Bid/types';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';

import {getCityLine} from 'pages/Bids/utils';

export const getTitle = (bids: Bid[], confirmedLines: ConfirmedLine[]): string => {
    const [bid] = bids;
    const [confirmedLine] = confirmedLines;
    if (bid) {
        return `${getCityLine(bid.pickupAddress)} - ${getCityLine(bid.deliveryAddress)}`;
    }

    return `${confirmedLine.pickupAddress} - ${confirmedLine.deliveryAddress}`;
};
