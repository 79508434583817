import {Dispatch} from 'redux';
import isEmpty from 'lodash/isEmpty';

import {CustomerFormValues} from 'core/entities/Customer/types';

import transformCustomerFormValuesToRequest from 'pages/Customers/mappers/transformCustomerFormValuesToRequest';

import {AppState, PaginationValues} from 'store/types';

import * as types from '../actionTypes';
import {getCustomersPagination, getSearchParams} from '../selectors';

import * as requests from './requests';

export function fetchCustomerList(search: string, pagination: PaginationValues) {
    return async function (dispatch: Dispatch<any>) {
        try {
            if (!isEmpty(search)) {
                const response = await requests.searchCustomers({search, pagination});
                dispatch({
                    type: types.LIST_CUSTOMERS_SEARCH,
                    payload: {searchParams: search, ...response.data},
                });
                return;
            }
            const response = await requests.fetchCustomerList(pagination);
            dispatch({
                type: types.LIST_CUSTOMERS_RECEIVED,
                payload: response.data,
            });
        } catch (err) {
            console.error(err);
        }
    };
}

export function searchCustomers(search: string, pagination: PaginationValues) {
    return async function (dispatch: any) {
        try {
            if (isEmpty(search)) {
                dispatch(fetchCustomerList(search, pagination));
                dispatch({
                    type: types.CLEAR_SEARCH_PARAMS,
                });
                return;
            }

            const response = await requests.searchCustomers({search, pagination});
            dispatch({
                type: types.LIST_CUSTOMERS_SEARCH,
                payload: {searchParams: search, ...response.data},
            });
        } catch (err) {
            console.error(err);
        }
    };
}

export function createCustomer(value: CustomerFormValues) {
    return async function (dispatch: any) {
        const transformParams = transformCustomerFormValuesToRequest(value);
        try {
            const response = await requests.createCustomer(transformParams);

            dispatch({
                type: types.CREATE_CUSTOMER,
                payload: response.data,
            });
        } catch (err) {
            return Promise.reject(err);
        }
    };
}

export function updateCustomer(value: CustomerFormValues, id: string) {
    return async function (dispatch: any) {
        const transformParams = transformCustomerFormValuesToRequest(value);
        try {
            const response = await requests.updateCustomer(transformParams, id);

            dispatch({
                type: types.UPDATE_CUSTOMER,
                payload: response.data,
            });
        } catch (err) {
            return Promise.reject(err);
        }
    };
}

export function archiveCustomer(id: string) {
    return async function (dispatch: any) {
        try {
            const response = await requests.archiveCustomer(id);

            dispatch({
                type: types.DELETE_CUSTOMER,
                payload: response.data,
            });
        } catch (err) {
            console.error(err);
        }
    };
}

export function restoreCustomer(id: string) {
    return async function (dispatch: any) {
        try {
            const response = await requests.restoreCustomer(id);

            dispatch({
                type: types.RESTORE_CUSTOMER,
                payload: response.data,
            });
        } catch (err) {
            console.error(err);
        }
    };
}

export const fetchCustomersPagination = (nextPage: number) => (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const pagination = getCustomersPagination(state);
    const search = getSearchParams(state);
    dispatch(fetchCustomerList(search, {...pagination, currentPage: nextPage}));
};
