import {AppSettings} from 'context/appSettings';
import {useEffect, useState} from 'react';

import {fetchAppSettings} from '../../requests';
import useIsLoggedInUser from '../useIsLoggedInUser';

export default function (): AppSettings | undefined {
    const [appSettings, setAppSettings] = useState<AppSettings>();
    const isUserLoggedIn = useIsLoggedInUser();
    useEffect(() => {
        let isRendered = true;
        if (isUserLoggedIn) {
            fetchAppSettings().then((settings) => {
                if (isRendered) {
                    setAppSettings(settings);
                }
            });
        }

        return () => {
            isRendered = false;
        };
    }, [isUserLoggedIn]);

    return appSettings;
}
