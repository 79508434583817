import {batch} from 'react-redux';

import {AppState, PaginationValues} from 'store/types';
import {showErrorModal} from 'store/actions/modalActions';

import {SearchParamsValues} from '../types';
import * as actionTypes from '../actionTypes';
import {getSearchParams, getBidsCurrentPagination, getConfirmedLinesCurrentPagination} from '../selectors';

import * as requests from './requests';

const getPagination = (state: AppState): {bids: PaginationValues; confirmedLines: PaginationValues} => {
    const bidsPagination = getBidsCurrentPagination(state);
    const confirmedLinesPagination = getConfirmedLinesCurrentPagination(state);
    return {
        bids: bidsPagination,
        confirmedLines: confirmedLinesPagination,
    };
};

const isNeedSearchByFullAddress = (searchParams: SearchParamsValues): boolean => {
    return (
        Boolean(searchParams.origin.city) &&
        Boolean(searchParams.origin.state) &&
        Boolean(searchParams.destination.city) &&
        Boolean(searchParams.destination.state)
    );
};

const isNeedSearchByStates = (searchParams: SearchParamsValues): boolean => {
    return (
        Boolean(searchParams.origin.state) &&
        Boolean(searchParams.destination.state) &&
        !searchParams.origin.city &&
        !searchParams.destination.city
    );
};

function searchByFullAddress(
    searchParams: SearchParamsValues,
    bidsPagination: PaginationValues,
    confirmedLinesPagination: PaginationValues,
) {
    return function (dispatch: any) {
        Promise.all([
            requests.searchBidsByFullAddress(searchParams, bidsPagination),
            requests.searchConfirmedLinesByRoute(searchParams, confirmedLinesPagination),
        ])
            .then(([searchedBids, searchedConfirmLines]): void => {
                batch(() => {
                    dispatch({
                        type: actionTypes.LIST_BIDS_RECEIVED,
                        payload: {
                            items: searchedBids.items,
                            pagination: searchedBids.pagination,
                        },
                    });
                    dispatch({
                        type: actionTypes.LIST_CONFIRMED_LINES_RECEIVED,
                        payload: {
                            items: searchedConfirmLines.items,
                            pagination: searchedConfirmLines.pagination,
                        },
                    });
                    dispatch({
                        type: actionTypes.SEARCH_PARAMS_CHANGED,
                        payload: {params: searchParams},
                    });
                });
            })
            .catch(() => {
                dispatch(showErrorModal());
            });
    };
}

function searchByStates(
    searchParams: SearchParamsValues,
    bidsPagination: PaginationValues,
    confirmedLinesPagination: PaginationValues,
) {
    return function (dispatch: any) {
        Promise.all([
            requests.searchBidsByStates(searchParams, bidsPagination),
            requests.searchConfirmedLinesByStates(searchParams, confirmedLinesPagination),
        ])
            .then(([searchedBids, searchedConfirmLines]): void => {
                batch(() => {
                    dispatch({
                        type: actionTypes.LIST_BIDS_RECEIVED,
                        payload: {
                            items: searchedBids.items,
                            pagination: searchedBids.pagination,
                        },
                    });
                    dispatch({
                        type: actionTypes.LIST_CONFIRMED_LINES_RECEIVED,
                        payload: {
                            items: searchedConfirmLines.items,
                            pagination: searchedConfirmLines.pagination,
                        },
                    });
                    dispatch({
                        type: actionTypes.SEARCH_PARAMS_CHANGED,
                        payload: {params: searchParams},
                    });
                });
            })
            .catch(() => {
                dispatch(showErrorModal());
            });
    };
}

function searchByAllFields(
    searchParams: SearchParamsValues,
    bidsPagination: PaginationValues,
    confirmedLinesPagination: PaginationValues,
) {
    return function (dispatch: any) {
        Promise.all([
            requests.searchBidsByMatchingAddress(searchParams, bidsPagination),
            requests.searchConfirmedLinesByMatchingAddress(searchParams, confirmedLinesPagination),
        ])
            .then(([searchedBids, searchedConfirmLines]): void => {
                batch(() => {
                    dispatch({
                        type: actionTypes.LIST_BIDS_RECEIVED,
                        payload: {
                            items: searchedBids.items,
                            pagination: searchedBids.pagination,
                        },
                    });
                    dispatch({
                        type: actionTypes.LIST_CONFIRMED_LINES_RECEIVED,
                        payload: {
                            items: searchedConfirmLines.items,
                            pagination: searchedConfirmLines.pagination,
                        },
                    });
                    dispatch({
                        type: actionTypes.SEARCH_PARAMS_CHANGED,
                        payload: {params: searchParams},
                    });
                });
            })
            .catch(() => {
                dispatch(showErrorModal());
            });
    };
}

const getBidsFetcherBySearchParams = (
    searchParams: SearchParamsValues,
    // eslint-disable-next-line no-shadow
): ((searchParams: SearchParamsValues, pagination: PaginationValues) => Promise<any>) => {
    if (isNeedSearchByFullAddress(searchParams)) {
        return requests.searchBidsByFullAddress;
    }
    if (isNeedSearchByStates(searchParams)) {
        return requests.searchBidsByStates;
    }
    return requests.searchBidsByMatchingAddress;
};

export function commonSearch(params: SearchParamsValues) {
    return function (dispatch: any, getState: () => AppState) {
        const pagination = getPagination(getState());
        // for avoid bugs with search incorrect result when we have some selected pagination
        // we should reset current select pagination pages for bids and confirmed lines before start search
        const bidsPagination: PaginationValues = {...pagination.bids, currentPage: 1};
        const confirmedLinesPagination: PaginationValues = {...pagination.confirmedLines, currentPage: 1};
        if (isNeedSearchByFullAddress(params)) {
            dispatch(searchByFullAddress(params, bidsPagination, confirmedLinesPagination));
            return;
        }

        if (isNeedSearchByStates(params)) {
            dispatch(searchByStates(params, bidsPagination, confirmedLinesPagination));
            return;
        }

        dispatch(searchByAllFields(params, bidsPagination, confirmedLinesPagination));
    };
}

export function searchBidsByPaginationPage(nextPage: number) {
    return function (dispatch: any, getState: any) {
        const state = getState();
        const searchParams = getSearchParams(state);
        const {bids} = getPagination(state);
        const isSearchParamsAreSet = Boolean(searchParams.searchValue);
        const bidsFetcher = getBidsFetcherBySearchParams(searchParams);
        bidsFetcher(searchParams, {...bids, currentPage: nextPage})
            .then((searchedBids) => {
                batch(() => {
                    dispatch({
                        type: actionTypes.LIST_BIDS_RECEIVED,
                        payload: {
                            items: searchedBids.items,
                            pagination: searchedBids.pagination,
                        },
                    });
                    // save selected page to local storage for case when user just change pagination page without enter search params
                    if (!isSearchParamsAreSet) {
                        // dispatch(setBidsPagePaginationNumber(nextPage));
                    }
                });
            })
            .catch(() => {
                dispatch(showErrorModal());
            });
    };
}

export function searchConfirmLinesByPaginationPage(newPage: number) {
    return function (dispatch: any, getState: any) {
        const state = getState();
        const searchParams = getSearchParams(state);
        const {confirmedLines} = getPagination(state);
        requests
            .searchConfirmedLinesByMatchingAddress(searchParams, {...confirmedLines, currentPage: newPage})
            .then((searchedConfirmLines) => {
                dispatch({
                    type: actionTypes.LIST_CONFIRMED_LINES_RECEIVED,
                    payload: {
                        items: searchedConfirmLines.items,
                        pagination: searchedConfirmLines.pagination,
                    },
                });
            })
            .catch(() => {
                dispatch(showErrorModal());
            });
    };
}
