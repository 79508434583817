import React from 'react';
import {Typography, Row, Col} from 'antd';
import Form from 'antd/lib/form';
import {Field} from 'formik';

import FormInput from 'components/ui/form/FormInput';

import {CustomerFormValues} from 'core/entities/Customer/types';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import CompanyStatusSelect from './components/CompanyStatusSelect';
import SpecialMarkSelect from './components/SpecialMarkSelect';

const {Title} = Typography;

const getName = getTypeFieldNameFactory<CustomerFormValues>();

const {Item} = Form;

const AccountInformation: React.FC<{isUpdate: boolean}> = ({isUpdate}) => {
    return (
        <>
            <Title level={5} style={{marginTop: '15px'}}>
                Account Information
            </Title>
            <Field
                name={getName('companyName')}
                type="input"
                label="Company"
                placeholder="Enter Company Name *"
                as={FormInput}
                required={false}
                isNeedIgnoreSpecialSymbols={true}
            />

            <Item label="Status:">
                <Row>
                    <Col span={15}>
                        <CompanyStatusSelect name={getName('status')} disabled={!isUpdate} />
                    </Col>
                    <Col span={8} offset={1}>
                        <SpecialMarkSelect name={getName('mark')} />
                    </Col>
                </Row>
            </Item>

            <Field
                name={getName('mcNumber')}
                type="number"
                placeholder="Registration Number"
                label="MC/FF/MX"
                as={FormInput}
                required={false}
                style={{mb: '10px'}}
                maxLength={30}
            />

            <Item label="USDOT:">
                <Field
                    name={getName('dotNumber')}
                    type="number"
                    placeholder="USDOT Number"
                    as={FormInput}
                    required={false}
                    maxLength={30}
                />
            </Item>
        </>
    );
};

export default AccountInformation;
