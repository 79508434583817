import {Customer} from 'core/entities/Customer/types';

import {PaginationResponse} from 'store/types';

export const LIST_CUSTOMERS_RECEIVED = 'LIST_CUSTOMERS_RECEIVED' as const;
export const LIST_CUSTOMERS_SEARCH = 'LIST_CUSTOMERS_SEARCH' as const;
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS' as const;
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER' as const;
export const RESTORE_CUSTOMER = 'RESTORE_CUSTOMER' as const;
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER' as const;
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER' as const;

type ListCustomersReceived = {
    type: typeof LIST_CUSTOMERS_RECEIVED;
    payload: {items: Customer[]; pagination: PaginationResponse};
};

type ListCustomersSearch = {
    type: typeof LIST_CUSTOMERS_SEARCH;
    payload: {items: Customer[]; pagination: PaginationResponse; searchParams: string};
};

type ClearSearchParams = {
    type: typeof CLEAR_SEARCH_PARAMS;
};

type DeleteCustomer = {
    type: typeof DELETE_CUSTOMER;
    payload: Customer;
};

type RestoreCustomer = {
    type: typeof RESTORE_CUSTOMER;
    payload: Customer;
};

type UpdateCustomer = {
    type: typeof UPDATE_CUSTOMER;
    payload: Customer;
};

type CreateCustomer = {
    type: typeof CREATE_CUSTOMER;
    payload: Customer;
};

export type CustomersActionTypes =
    | ListCustomersReceived
    | ListCustomersSearch
    | ClearSearchParams
    | DeleteCustomer
    | RestoreCustomer
    | UpdateCustomer
    | CreateCustomer;
