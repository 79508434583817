import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {receivedBidById} from 'store/actions/bidActions';
import {receivedCustomerById} from 'store/actions/customerActions';

interface OwnProps {
    receivedBidById: (bidId: string) => void;
    receivedCustomerById: (customerId: string) => void;
}

export default function (): OwnProps {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return {
        receivedBidById: (bidId: string) => dispatch(receivedBidById(bidId, navigate)),
        receivedCustomerById: (customerId: string) => dispatch(receivedCustomerById(customerId, navigate)),
    };
}
