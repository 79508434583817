import React from 'react';
import {useFormikContext} from 'formik';
import Button from 'antd/lib/button';
import {Flex} from 'antd';

type OwnProps = {
    loading: boolean;
    isUpdate: boolean;
};

const ModalFooter: React.FC<OwnProps> = ({loading, isUpdate}) => {
    const {handleSubmit, resetForm} = useFormikContext();

    return (
        <Flex justify="space-between">
            <Button onClick={() => resetForm()}>Clear Fields</Button>
            <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => handleSubmit()}
                className="secondary-button"
            >
                {isUpdate ? 'Update' : 'Create'} Customer
            </Button>
        </Flex>
    );
};

export default ModalFooter;
