import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import isFunction from 'lodash/isFunction';

import * as appActions from 'store/actions';
import {getModalData} from 'store/reducers/appData/selectors';

import globalRegister from 'utils/globalRegister';

import modalsMap from '../modalsMap';

type HandlersMap = {
    onSubmit?: (fields: any) => void;
    onCallSameBid?: () => void;
    onCreateSameBid?: () => void;
};

const ModalContainer: React.FC = () => {
    const modalData = useSelector((state: any) => getModalData(state));

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(appActions.hideModal());
    };

    if (!modalData) {
        return null;
    }

    const {name, data, closeBeforeSubmit = true} = modalData;

    const handlersMap: HandlersMap = globalRegister.getHandlers();
    const onSubmit = (fields: any): void => {
        if (closeBeforeSubmit) {
            onClose();
        }
        if (isFunction(handlersMap.onSubmit)) {
            handlersMap.onSubmit(fields);
        }
    };

    const CurrentModal = modalsMap[name];

    return (
        <CurrentModal
            data={data}
            handlers={handlersMap as Required<HandlersMap>}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};

export default ModalContainer;
