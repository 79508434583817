import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';

import {CustomerFormValues} from 'core/entities/Customer/types';

import {AddCustomerToBidFormValues, Filters, SearchParamsValues} from 'pages/Bids/types';

import {PaginationValues} from 'store/types';

import * as bidActions from '../actions/bidActions';
import * as searchActions from '../actions/searchActions';
import * as filterActions from '../actions/filterActions';
import * as customerActions from '../actions/customerActions';

type OwnProps = {
    searchBidsAndConfirmLines: (searchParams: SearchParamsValues) => void;
    filterBids: (filters: Filters) => void;
    changePaginationPage: (newPage: number) => void;
    fetchAllBids: (pagination: PaginationValues) => void;
    setArchivedBid: (id: string) => void;
    callBid: (id: string) => void;
    recallBid: (id: string) => void;
    addCustomerToBid: (values: AddCustomerToBidFormValues, bidID: string) => void;
    createCustomer: (values: CustomerFormValues) => void;
};

export default function (): OwnProps {
    const dispatch = useDispatch<Dispatch<any>>();
    return {
        searchBidsAndConfirmLines: (searchParams: SearchParamsValues) => {
            dispatch(searchActions.commonSearch(searchParams));
        },
        filterBids: (filters: Filters) => dispatch(filterActions.commonFetchFilteredBids(filters)),
        changePaginationPage: (newPage: number) => {
            dispatch(bidActions.fetchBidPagination(newPage));
        },
        fetchAllBids: (pagination: PaginationValues) => dispatch(bidActions.fetchAllBids(pagination)),
        setArchivedBid: (id: string) => dispatch(bidActions.setArchivedBid(id)),
        callBid: (id: string) => dispatch(bidActions.callBid(id)),
        recallBid: (id: string) => dispatch(bidActions.recallBid(id)),
        addCustomerToBid: (values: AddCustomerToBidFormValues, bidID: string) => {
            dispatch(bidActions.addCustomerToBid(values, bidID));
        },
        createCustomer: (values: CustomerFormValues) => {
            dispatch(customerActions.createCustomer(values));
        },
    };
}
