import React from 'react';
import Text from 'antd/lib/typography/Text';
import Table from 'antd/lib/table';

import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';

import {TruckType} from 'pages/Bids/types';
import {truckNames} from 'pages/Bids/constants';

interface OwnProps {
    confirmedLines: ConfirmedLine[];
}

const ConfirmedLinesContent: React.FC<OwnProps> = ({confirmedLines}) => {
    const columns = [
        {
            title: 'FROM',
            dataIndex: 'pickupAddress',
            key: 'pickupAddress',
            render: (pickupAddress: string) => <Text>{pickupAddress}</Text>,
            width: 120,
        },
        {
            title: 'TO',
            dataIndex: 'deliveryAddress',
            key: 'deliveryAddress',
            render: (deliveryAddress: string) => <Text>{deliveryAddress}</Text>,
            width: 120,
        },
        {
            title: 'TRUCK TYPE',
            dataIndex: 'truckType',
            key: 'truckType',
            render: (truckType: TruckType): React.ReactNode => <Text strong>{truckNames[truckType]}</Text>,
            width: 120,
        },
        {
            title: 'RATE',
            dataIndex: 'rate',
            key: 'rate',
            render: (rate: number): React.ReactNode => <Text>${rate}</Text>,
            width: 70,
        },
        {
            title: 'COMPANY',
            dataIndex: 'company',
            key: 'company',
            render: (company: string | undefined = ''): React.ReactNode => <Text>{company}</Text>,
            width: 100,
        },
        {
            title: 'DETAILS',
            dataIndex: 'details',
            key: 'details',
            render: (details: string | undefined = ''): React.ReactNode => <Text>{details}</Text>,
            width: 100,
        },
    ];
    return (
        <div>
            <Text>{confirmedLines.length} the same lane(s)</Text>

            <Table
                className="custom-table"
                rowKey={(confirmedLine: ConfirmedLine): string => confirmedLine.id as string}
                bordered={true}
                dataSource={confirmedLines}
                columns={columns}
                pagination={false}
            />
        </div>
    );
};

export default ConfirmedLinesContent;
