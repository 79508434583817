import {DateTime} from 'luxon';

import {getDateTimeFromISO} from 'utils/date';

export const getDifferenceTimeFromNow = (date?: string): string => {
    if (!date) {
        return '-';
    }

    const createDateByISO = getDateTimeFromISO(date);

    const dateNow = DateTime.local();

    const [years, months, days, hours, minutes, seconds] = dateNow
        .diff(createDateByISO, 'milliseconds')
        .toFormat('YY:MM:dd:hh:mm:ss')
        .split(':')
        .map(Number);

    const differenceDate = [
        years > 0 ? `${years} year(s)` : null,
        months > 0 ? `${months} month(s)` : null,
        days > 0 ? `${days} day(s)` : null,
        hours > 0 ? `${hours} hour(s)` : null,
        minutes > 0 ? `${minutes} minute(s)` : null,
        seconds > 0 ? `${seconds} second(s)` : null,
    ].filter((data: any): boolean => data);

    differenceDate.push(differenceDate.length > 0 ? 'ago' : 'just now');

    return differenceDate.join(' ');
};
