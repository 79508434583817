import {CompanyStatusType, SpecialMarkType} from 'core/entities/Customer/types';
import {COMPANY_STATUS, SPECIAL_MARK} from 'core/entities/Customer/constants';

export const TEXT_FIELD_LENGTH = 144;

export const modalNames = {
    createCustomer: 'ADD_CUSTOMER_MODAL',
    deleteCustomer: 'DELETE_CUSTOMER_MODAL',
};

export const statusNames: Record<CompanyStatusType, string> = {
    [COMPANY_STATUS.archived.code]: COMPANY_STATUS.archived.name,
    [COMPANY_STATUS.approved.code]: COMPANY_STATUS.approved.name,
    [COMPANY_STATUS.needToVerify.code]: COMPANY_STATUS.needToVerify.name,
};

export const statusColors: Record<CompanyStatusType, string> = {
    [COMPANY_STATUS.archived.code]: '#00000026',
    [COMPANY_STATUS.approved.code]: '#B7EB8F',
    [COMPANY_STATUS.needToVerify.code]: '#FFE58F',
};

export const specialMarkNames: Record<SpecialMarkType, string> = {
    [SPECIAL_MARK.dnu.code]: SPECIAL_MARK.dnu.name,
    [SPECIAL_MARK.locked.code]: SPECIAL_MARK.locked.name,
};
