import {CreateBidFormValues} from '../types';

export const initialCreateBidValues: CreateBidFormValues = {
    pickupCityLine: '',
    pickupAmount: 1,
    deliveryCityLine: '',
    deliveryAmount: 1,
    note: '',
    files: [],
};
