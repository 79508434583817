import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import Layout from 'antd/es/layout';

import transparentIcon from 'assets/images/transparent.png';

import LoaderSpinner from 'components/ui/LoaderSpinner';

import Header from './components/layout/header/index';
import Main from './components/layout/main/index';
import useIsLoggedInUser from './hooks/useIsLoggedInUser';
import useInitWebsocketConnect from './hooks/useInitWebsocketConnect';
import AppContext from './AppContext';

import './assets/css/custom.scss';

const App: React.FC = () => {
    const isUserLoggedIn = useIsLoggedInUser();
    const connectToWebsocket = useInitWebsocketConnect();

    useEffect(() => {
        connectToWebsocket();
    }, [connectToWebsocket]);

    useEffect(() => {
        const setFaviconFromDomainSettings = (): void => {
            const favicon = document.getElementById('favicon') as HTMLLinkElement | null;
            if (favicon) {
                favicon.href = transparentIcon;
            }
        };
        setFaviconFromDomainSettings();
    }, []);

    return (
        <AppContext>
            <BrowserRouter>
                <div>
                    <Layout>
                        {isUserLoggedIn ? <Header /> : null}
                        <Main />
                    </Layout>
                    <LoaderSpinner />
                </div>
            </BrowserRouter>
        </AppContext>
    );
};

export default App;
