import {AxiosResponse} from 'axios';

import {getPromiseWithDelay} from 'utils/getPromiseWithDelay';

import {RECEIVED_BID_BY_ID} from './bidsActionTypes';
import * as requests from './requests';
import {showErrorModal} from './modalActions';

const DELAY_TIME = 200;

export function receivedBidById(bidId: string, navigate: (path: string) => void): any {
    return async function (dispatch: any): Promise<void> {
        try {
            await navigate('/bids');
            const response: AxiosResponse = await getPromiseWithDelay(DELAY_TIME).then(() =>
                requests.fetchBidById(bidId),
            );
            dispatch({
                type: RECEIVED_BID_BY_ID,
                payload: response.data,
            });
        } catch (error) {
            dispatch(showErrorModal());
            console.error('Error on fetch bid by id:', error);
        }
    };
}
