import {AxiosResponse} from 'axios';
import {Dispatch} from 'redux';
import isEmpty from 'lodash/isEmpty';

import {UserData} from 'core/entities/User/types';

import {AppState, PaginationValues} from 'store/types';

import * as types from '../actionTypes';
import {getSearchParams, getUsersPagination} from '../selectors';

import * as requests from './requests';

export function fetchUserList(searchParams: string, pagination: PaginationValues) {
    return function (dispatch: any) {
        if (!isEmpty(searchParams)) {
            requests
                .searchUsers({search: searchParams, pagination})
                .then((response) => {
                    dispatch({
                        type: types.LIST_USERS_SEARCH,
                        payload: {searchParams, ...response.data},
                    });
                })
                .catch((err) => console.error(err));
        } else {
            requests
                .fetchUserList(pagination)
                .then((response) => response.data)
                .then((response) => {
                    dispatch({
                        type: types.LIST_USERS_RECEIVED,
                        payload: response,
                    });
                })
                .catch((err) => console.error(err));
        }
    };
}

export function searchUsers(search: string, pagination: PaginationValues) {
    return function (dispatch: any) {
        if (!search || search.length === 0) {
            dispatch(fetchUserList(search, pagination));
            dispatch({
                type: types.CLEAR_SEARCH_PARAMS,
            });
            return;
        }
        requests
            .searchUsers({search, pagination})
            .then((response) => response.data)
            .then((data) => {
                dispatch({
                    type: types.LIST_USERS_SEARCH,
                    payload: {searchParams: search, ...data},
                });
            })
            .catch((err) => console.error(err));
    };
}

export function createUser(user: UserData) {
    return function (): Promise<AxiosResponse<void>> {
        return new Promise((resolve, reject) => {
            requests.createUser(user).then(resolve).catch(reject);
        });
    };
}

export function updateUser(user: UserData) {
    return function () {
        return new Promise((resolve, reject) => {
            requests.updateUser(user).then(resolve).catch(reject);
        });
    };
}

export function deleteUser(id: string) {
    return function () {
        requests.deleteUser(id).catch((err) => console.error(err));
    };
}

export const fetchUserPagination = (nextPage: number) => (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const pagination = getUsersPagination(state);
    const search = getSearchParams(state);
    dispatch(fetchUserList(search, {...pagination, currentPage: nextPage}));
};
