import React from 'react';

import Select from 'components/ui/form/Select';

import {SPECIAL_MARK} from 'core/entities/Customer/constants';

const spacialMarkOptions = [
    {value: SPECIAL_MARK.dnu.code, label: SPECIAL_MARK.dnu.name},
    {value: SPECIAL_MARK.locked.code, label: SPECIAL_MARK.locked.name},
];

type OwnProps = {
    name: string;
};

const SpecialMarkSelect: React.FC<OwnProps> = (props) => {
    const {name} = props;

    return <Select name={name} placeholder="Special Mark" options={spacialMarkOptions} />;
};

export default SpecialMarkSelect;
