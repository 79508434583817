import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import Layout from 'antd/lib/layout';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import {blue} from '@ant-design/colors';
import {ExportOutlined, BellFilled} from '@ant-design/icons';
import config from 'config';

import {UserData} from 'core/entities/User/types';
import getUserFullNameWithTeamCode from 'core/entities/User/getUserFullNameWithTeamCode';

import useStateUserData from 'hooks/useStateUserData';
import useStateUserIsAdmin from 'hooks/useStateUserIsAdmin';

import Logo from './components/Logo';
import Notifications from './components/Notifications';
import useUserActions from './hooks/useUserActions';
import styles from './header.module.scss';

const Header: React.FC = () => {
    const wrapClasses = styles.wrap;
    const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
    const navigate = useNavigate();
    const isAdmin = useStateUserIsAdmin();
    const location = useLocation();
    const user: UserData | undefined = useStateUserData();
    const {logout} = useUserActions();
    const iconStyle = {
        fontSize: '24px',
    };

    const handleOpenNotification = (): void => {
        setIsOpenNotification(!isOpenNotification);
    };

    const menuItems = [
        {
            key: '/bids',
            label: 'Bids',
            onClick: () => navigate('/bids'),
        },
        {
            key: '/users',
            label: 'Users',
            onClick: () => navigate('/users'),
        },
        {
            key: '/customers',
            label: 'Customers',
            onClick: () => navigate('/customers'),
        },
        {
            key: '/reports',
            label: 'Reports',
            onClick: () => navigate('/reports'),
        },
    ];

    return (
        <>
            <Layout.Header className={wrapClasses}>
                <Logo />

                {isAdmin ? (
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        color={blue.primary}
                        selectedKeys={[location.pathname]}
                        style={{width: 340}}
                        items={menuItems}
                    />
                ) : null}

                <a className={styles.link} target="_blank" rel="noopener noreferrer" href={config.RULES_URL}>
                    Rules
                </a>

                <div className={styles.item}>
                    <Text>{user?.company}</Text>
                    <Text>{user && getUserFullNameWithTeamCode(user)}</Text>
                    <Button
                        onClick={handleOpenNotification}
                        style={{
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                        }}
                        type="primary"
                        icon={<BellFilled style={iconStyle} />}
                    />
                    <Button
                        onClick={logout}
                        style={{
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                        }}
                        type="primary"
                        icon={<ExportOutlined style={iconStyle} />}
                    />
                </div>
                <Notifications close={handleOpenNotification} isOpen={isOpenNotification} />
            </Layout.Header>
        </>
    );
};

export default Header;
