import React from 'react';
import Modal from 'antd/lib/modal';
import {Meta} from 'antd/lib/list/Item';
import Text from 'antd/lib/typography/Text';
import {WarningOutlined} from '@ant-design/icons';
import {Flex} from 'antd';

import {Customer} from 'core/entities/Customer/types';

import useCustomersActions from 'pages/Customers/hooks/useCustomersActions';

import styles from './deleteUserModal.module.scss';

type OwnProps = {onClose: () => void; data: Customer};

const DeleteUserModal: React.FC<OwnProps> = ({onClose, data}) => {
    const {archiveCustomer} = useCustomersActions();
    const {companyName, id} = data;

    const handleOk = () => {
        archiveCustomer(id);
        onClose();
    };

    return (
        <Modal
            className={styles.modal}
            visible={true}
            closable={false}
            title={null}
            onOk={() => handleOk()}
            onCancel={() => onClose()}
            okText="Delete"
            okButtonProps={{danger: true}}
        >
            <Meta
                title={
                    <Flex style={{fontSize: '20px', gridGap: '10px', fontWeight: 600}}>
                        <WarningOutlined style={{color: 'red'}} /> Do you want to Delete Customer?
                    </Flex>
                }
                description={
                    <Text>
                        <strong>{companyName}</strong> will be removed from the Bid Rates Service. This operation cannot
                        be undone.
                    </Text>
                }
            />
        </Modal>
    );
};

export default DeleteUserModal;
