import {SpecialMarkType} from '../types';

export const COMPANY_STATUS = {
    needToVerify: {code: 'NEED_TO_VERIFY', name: 'Need to verify'},
    approved: {code: 'APPROVED', name: 'Approved'},
    archived: {code: 'ARCHIVED', name: 'Archived'},
} as const;

export const SPECIAL_MARK = {
    dnu: {code: 'DNU', name: 'Do not use'},
    locked: {code: 'LOCKED', name: 'Locked'},
} as const;

export const customerSpecialMarkName: Record<SpecialMarkType, string> = {
    [SPECIAL_MARK.dnu.code]: SPECIAL_MARK.dnu.name,
    [SPECIAL_MARK.locked.code]: SPECIAL_MARK.locked.name,
};
