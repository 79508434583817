import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';

import {UserData} from 'core/entities/User/types';

import {PaginationValues} from 'store/types';

import {createUser, fetchUserList, deleteUser, updateUser, fetchUserPagination} from '../actions/userActions';

interface OwnProps {
    createUser: (value: UserData) => any;
    updateUser: (value: UserData) => any;

    fetchUserList: (searchParams: string, pagination: PaginationValues) => void;
    deleteUser: (id: string) => void;
    changePaginationPage: (newPage: number) => void;
}

export default function (): OwnProps {
    const dispatch = useDispatch<Dispatch<any>>();
    return {
        createUser: (value: UserData) => dispatch(createUser(value)),
        updateUser: (value: UserData) => dispatch(updateUser(value)),
        fetchUserList: (searchParams: string, pagination: PaginationValues) =>
            dispatch(fetchUserList(searchParams, pagination)),
        deleteUser: (id: string) => dispatch(deleteUser(id)),
        changePaginationPage: (newPage: number) => {
            dispatch(fetchUserPagination(newPage));
        },
    };
}
