import {Customer} from 'core/entities/Customer/types';

export const RECEIVED_CUSTOMER_BY_ID = 'RECEIVED_CUSTOMER_BY_ID' as const;

export type ReceivedCustomerById = {
    type: typeof RECEIVED_CUSTOMER_BY_ID;
    payload: Customer;
};

export type CustomersGlobalActionTypes = ReceivedCustomerById;
