import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';

import {CustomerFormValues} from 'core/entities/Customer/types';

import {PaginationValues} from 'store/types';

import {
    fetchCustomerList,
    createCustomer,
    archiveCustomer,
    restoreCustomer,
    fetchCustomersPagination,
    updateCustomer,
} from '../actions/customerActions';

type OwnProps = {
    createCustomer: (value: CustomerFormValues) => void;
    updateCustomer: (value: CustomerFormValues, id: string) => void;
    fetchCustomersList: (search: string, pagination: PaginationValues) => void;
    archiveCustomer: (id: string) => void;
    restoreCustomer: (id: string) => void;
    changePaginationPage: (newPage: number) => void;
};

export default function (): OwnProps {
    const dispatch = useDispatch<Dispatch<any>>();
    return {
        createCustomer: (value: CustomerFormValues) => dispatch(createCustomer(value)),
        updateCustomer: (value: CustomerFormValues, id: string) => dispatch(updateCustomer(value, id)),
        fetchCustomersList: (search: string, pagination: PaginationValues) =>
            dispatch(fetchCustomerList(search, pagination)),
        archiveCustomer: (customerID: string) => dispatch(archiveCustomer(customerID)),
        restoreCustomer: (customerID: string) => dispatch(restoreCustomer(customerID)),
        changePaginationPage: (newPage: number) => {
            dispatch(fetchCustomersPagination(newPage));
        },
    };
}
