import {Bid, BidCustomerInfo} from 'core/entities/Bid/types';
import {UserData} from 'core/entities/User/types';
import {Notification} from 'core/entities/Notification/types';
import {Customer} from 'core/entities/Customer/types';

export const WEB_SOCKET_INIT = 'WEB_SOCKET_INIT';
export const WEB_SOCKET_CONNECTED = 'WEB_SOCKET_CONNECTED' as const;
export const WEB_SOCKET_DISCONNECT = 'WEB_SOCKET_DISCONNECT';
export const WEB_SOCKET_RECONNECT = 'WEB_SOCKET_RECONNECT';
export const WEB_SOCKET_DISCONNECTED = 'WEB_SOCKET_DISCONNECTED' as const;

export const WEB_SOCKET_RECEIVED_CREATED_BID = 'WEB_SOCKET_RECEIVED_CREATED_BID' as const;
export const WEB_SOCKET_RECEIVED_ADD_CUSTOMER_INFO_TO_BID = 'WEB_SOCKET_RECEIVED_ADD_CUSTOMER_INFO_TO_BID' as const;
export const WEB_SOCKET_RECEIVED_CREATED_BID_NOTE = 'WEB_SOCKET_RECEIVED_CREATED_BID_NOTE' as const;
export const WEB_SOCKET_RECEIVED_UPDATE_BID_NOTE = 'WEB_SOCKET_RECEIVED_UPDATE_BID_NOTE' as const;
export const WEB_SOCKET_RECEIVED_CALL_BID = 'WEB_SOCKET_RECEIVED_CALL_BID' as const;

export const WEB_SOCKET_RECEIVED_CREATED_USER = 'WEB_SOCKET_RECEIVED_CREATED_USER' as const;
export const WEB_SOCKET_RECEIVED_REMOVED_USER = 'WEB_SOCKET_RECEIVED_REMOVED_USER' as const;
export const WEB_SOCKET_RECEIVED_UPDATED_USER = 'WEB_SOCKET_RECEIVED_UPDATED_USER' as const;

export const WEB_SOCKET_RECEIVED_NOTIFICATION = 'WEB_SOCKET_RECEIVED_NOTIFICATION' as const;

export const WEB_SOCKET_RECEIVED_CREATED_CUSTOMER = 'WEB_SOCKET_RECEIVED_CREATED_CUSTOMER' as const;

export interface WsConnected {
    type: typeof WEB_SOCKET_CONNECTED;
    payload: {socketID: number};
}

export interface WsDisconnected {
    type: typeof WEB_SOCKET_DISCONNECTED;
}

export interface ReceivedCreatedBid {
    type: typeof WEB_SOCKET_RECEIVED_CREATED_BID;
    payload: {newBid: Bid; currentUser: UserData};
}

export interface ReceivedAddCustomerInfoToBid {
    type: typeof WEB_SOCKET_RECEIVED_ADD_CUSTOMER_INFO_TO_BID;
    payload: {bidID: string; customerInfo: BidCustomerInfo};
}

export interface ReceivedCreatedCustomer {
    type: typeof WEB_SOCKET_RECEIVED_CREATED_CUSTOMER;
    payload: {newCustomer: Customer; currentUser: UserData};
}

export interface ReceivedCreatedBidNote {
    type: typeof WEB_SOCKET_RECEIVED_CREATED_BID_NOTE;
    payload: {newBid: Bid; currentUser: UserData};
}

export interface ReceivedUpdatedBidNote {
    type: typeof WEB_SOCKET_RECEIVED_UPDATE_BID_NOTE;
    payload: {
        props: {noteID: string; bidID: string; customerInfo: {name: string; quoteSource: string; mark: string}};
        currentUser: UserData;
    };
}

export interface ReceivedCreatedUser {
    type: typeof WEB_SOCKET_RECEIVED_CREATED_USER;
    payload: {newUser: UserData};
}

export interface ReceivedRemovedUser {
    type: typeof WEB_SOCKET_RECEIVED_REMOVED_USER;
    payload: {id: string};
}

export interface ReceivedUpdatedUser {
    type: typeof WEB_SOCKET_RECEIVED_UPDATED_USER;
    payload: {updatedUser: UserData};
}

export interface ReceivedCallBidNote {
    type: typeof WEB_SOCKET_RECEIVED_CALL_BID;
    payload: {callBid: Bid; currentUser: UserData};
}

export interface ReceivedNotification {
    type: typeof WEB_SOCKET_RECEIVED_NOTIFICATION;
    payload: {notification: Notification};
}

export type WebSocketActionTypes =
    | WsConnected
    | WsDisconnected
    | ReceivedCreatedBid
    | ReceivedAddCustomerInfoToBid
    | ReceivedCreatedCustomer
    | ReceivedCreatedBidNote
    | ReceivedUpdatedBidNote
    | ReceivedCreatedUser
    | ReceivedRemovedUser
    | ReceivedUpdatedUser
    | ReceivedCallBidNote
    | ReceivedNotification;
