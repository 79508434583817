import bidsModalMap from 'pages/Bids/components/modals/modalsMap';
import usersModalMap from 'pages/Users/components/modals/modalsMap';
import customersModalMap from 'pages/Customers/components/modals/modalsMap';

const allModalsMap = {
    ...bidsModalMap,
    ...usersModalMap,
    ...customersModalMap,
};

export default allModalsMap;
