import React, {useEffect, useState} from 'react';
import {Typography, Row, Col} from 'antd';

import {Customer} from 'core/entities/Customer/types';
import {UserData} from 'core/entities/User/types';

import {getUserById} from 'pages/Users/actions/requests';
import {getFullUserName} from 'pages/Users/components/UsersList/utils';

const {Paragraph} = Typography;

type OwnProps = {
    customer: Customer;
};

const AddedByInformation: React.FC<OwnProps> = ({customer}) => {
    const [user, setUser] = useState<UserData | null>(null);

    useEffect(() => {
        if (customer.createByUserID) {
            getUserById(customer.createByUserID).then((response) => {
                setUser(response.data.user);
            });
        }
    }, []);

    if (!user) {
        return null;
    }

    return (
        <Row style={{marginTop: '20px'}}>
            <Col
                span={24}
                style={{
                    background: '#F0F0F0',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    padding: '5px 0 0 10px',
                }}
            >
                <Paragraph style={{marginBottom: '5px'}}>
                    <strong>Customer was added by:</strong>
                </Paragraph>
            </Col>
            <Row
                style={{
                    fontSize: '14px',
                    background: '#FAFAFA',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    padding: '10px 0 0 10px',
                    width: '100%',
                }}
            >
                <Col span={6}>
                    <Paragraph style={{color: '#000000A6', marginBottom: 2}}>Name</Paragraph>
                </Col>
                <Col span={18}>
                    <Paragraph style={{fontWeight: 600, marginBottom: 2}}>{getFullUserName(user)}</Paragraph>
                </Col>
                <Col span={6}>
                    <Paragraph style={{color: '#000000A6', marginBottom: 2}}>Email</Paragraph>
                </Col>
                <Col span={18}>
                    <Paragraph style={{marginBottom: 2}}>{user.email}</Paragraph>
                </Col>
                <Col span={6}>
                    <Paragraph style={{color: '#000000A6'}}>Company / Team</Paragraph>
                </Col>
                <Col span={18}>
                    <Paragraph>
                        {user.company} / {user.teamCode}
                    </Paragraph>
                </Col>
            </Row>
        </Row>
    );
};

export default AddedByInformation;
