import {Bid} from 'core/entities/Bid/types';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';

import {PaginationValues} from 'store/types';

import {CreateBidFormValues, Filters, SearchParamsValues} from '../types';

export const LIST_BIDS_RECEIVED = 'BIDS_PAGE_LIST_BIDS_RECEIVED' as const;
export const LIST_CONFIRMED_LINES_RECEIVED = 'LIST_CONFIRMED_LINES_RECEIVED' as const;
export const ADD_CONFIRMED_LINE = 'ADD_CONFIRMED_LINE' as const;
export const UPDATE_CONFIRMED_LINE = 'UPDATE_CONFIRMED_LINE' as const;
export const REMOVE_CONFIRMED_LINE = 'REMOVE_CONFIRMED_LINE' as const;
export const SEARCH_PARAMS_CHANGED = 'BIDS_PAGE_SEARCH_PARAMS_CHANGED' as const;
export const FILTERS_PARAMS_CHANGED = 'BIDS_PAGE_FILTERS_PARAMS_CHANGED' as const;
export const CLEAR_FILTERS_PARAMS = 'BIDS_PAGE_CLEAR_FILTERS_PARAMS' as const;
export const SET_ARCHIVED_BID = 'BIDS_PAGE_SET_ARCHIVED_BID' as const;
export const CHANGE_BIDS_PAGE = 'CHANGE_BIDS_PAGE' as const;
export const CHANGE_CONFIRMED_LINES_PAGE = 'CHANGE_CONFIRMED_LINES_PAGE' as const;
export const SET_VALUES_TO_CREATE_BID_MODAL = 'SET_VALUES_CREATE_BID_MODAL' as const;
export const CLEAR_VALUES_CREATE_BID_MODAL = 'CLEAR_VALUES_CREATE_BID_MODAL' as const;

export const CLEAR_BIDS_GALLERY = 'CLEAR_BIDS_GALLERY' as const;
export const OPEN_BIDS_GALLERY = 'OPEN_BIDS_GALLERY' as const;

interface SetArchivedBid {
    type: typeof SET_ARCHIVED_BID;
    payload: {id: string};
}

interface ChangeBidsPage {
    type: typeof CHANGE_BIDS_PAGE;
    payload: {currentPage: number};
}

interface ChangeConfirmedLinesPage {
    type: typeof CHANGE_CONFIRMED_LINES_PAGE;
    payload: {currentPage: number};
}

interface ListBidsReceived {
    type: typeof LIST_BIDS_RECEIVED;
    payload: {items: Bid[]; pagination: PaginationValues};
}

export interface ListConfirmedLinesReceived {
    type: typeof LIST_CONFIRMED_LINES_RECEIVED;
    payload: {items: ConfirmedLine[]; pagination: PaginationValues};
}

interface SearchParamsChanged {
    type: typeof SEARCH_PARAMS_CHANGED;
    payload: {
        params: SearchParamsValues;
    };
}

export interface ClearBidsGallery {
    type: typeof CLEAR_BIDS_GALLERY;
}

export interface OpenBidsGallery {
    type: typeof OPEN_BIDS_GALLERY;
    payload: {
        bidId: string;
        noteId: string;
    };
}

export interface FiltersParamsChanged {
    type: typeof FILTERS_PARAMS_CHANGED;
    payload: {
        filtersParams: Filters;
    };
}

export interface ClearFiltersParams {
    type: typeof CLEAR_FILTERS_PARAMS;
}

export interface AddConfirmedLine {
    type: typeof ADD_CONFIRMED_LINE;
    payload: {
        item: ConfirmedLine;
    };
}

export interface UpdateConfirmedLine {
    type: typeof UPDATE_CONFIRMED_LINE;
    payload: {
        item: ConfirmedLine;
    };
}

export interface RemoveConfirmedLine {
    type: typeof REMOVE_CONFIRMED_LINE;
    payload: {
        confirmedLineId: string;
    };
}

export interface SetValuesToCreateBidModal {
    type: typeof SET_VALUES_TO_CREATE_BID_MODAL;
    payload: CreateBidFormValues;
}

export interface ClearValuesCreateBidModal {
    type: typeof CLEAR_VALUES_CREATE_BID_MODAL;
}

export type BidsActionTypes =
    | ListBidsReceived
    | ListConfirmedLinesReceived
    | SearchParamsChanged
    | SetArchivedBid
    | ChangeBidsPage
    | ChangeConfirmedLinesPage
    | ClearBidsGallery
    | OpenBidsGallery
    | FiltersParamsChanged
    | ClearFiltersParams
    | AddConfirmedLine
    | UpdateConfirmedLine
    | RemoveConfirmedLine
    | SetValuesToCreateBidModal
    | ClearValuesCreateBidModal;
