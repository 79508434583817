const isUseLinkOnRules = () => {
    const allowedHosts = [
        'localhost:3000',
        'tms-stage-bid-rates.rhinocodes.com',
        'bidus.load-info.com',
        'bidmx.load-info.com',
        'seal.dispatchland.com',
        'bids-stage.rhinocodes.org',
    ];
    const currentHost = window.location.host;
    return allowedHosts.includes(currentHost);
};

export default {
    IS_DEV_MODE: process.env.NODE_ENV === 'development',
    BUILD_HASH: process.env.NODE_ENV === 'development' ? '123456789' : (process.env.REACT_APP_BUILD_ID as string),
    API_URL: '/api',
    RATES_CALCULATOR_URL: 'https://rates-calc.dispatchland.com/',
    RULES_URL: 'https://sites.google.com/load-info.com/generalrules',
    isUseRulesLink: isUseLinkOnRules(),
};
