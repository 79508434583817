import {UserData} from 'core/entities/User/types';

export const SHOW_LOADER_SPINNER = 'SHOW_LOADER_SPINNER';
export const HIDE_LOADER_SPINNER = 'HIDE_LOADER_SPINNER';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const SET_USER_BIDS_PAGE_PAGINATION_NUMBER = 'SET_USER_BIDS_PAGE_PAGINATION_NUMBER';
export const SET_USER_INFO = 'SET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';

export interface ShowModal {
    type: typeof SHOW_MODAL;
    payload: {name: string; data: any};
}

export interface HideModal {
    type: typeof HIDE_MODAL;
}

export interface ShowLoaderSpinner {
    type: typeof SHOW_LOADER_SPINNER;
}

export interface HideLoaderSpinner {
    type: typeof HIDE_LOADER_SPINNER;
}

export interface UserLoggedIn {
    type: typeof USER_LOGGED_IN;
    payload: {user: UserData};
}

export interface UserLoggedOut {
    type: typeof USER_LOGGED_OUT;
}

export interface SetUserBidsPagePaginationNumber {
    type: typeof SET_USER_BIDS_PAGE_PAGINATION_NUMBER;
    payload: {pageNumber: number};
}

export interface SetUserInfo {
    type: typeof SET_USER_INFO;
    payload: {
        email: string;
        password: string;
    };
}

export interface ClearUserInfo {
    type: typeof CLEAR_USER_INFO;
}

export type AppActionTypes =
    | ShowModal
    | HideModal
    | ShowLoaderSpinner
    | HideLoaderSpinner
    | SetUserInfo
    | ClearUserInfo;
