import isEqual from 'lodash/isEqual';

import {CreateBidFormValues} from 'pages/Bids/types';
import {initialCreateBidValues} from 'pages/Bids/data';

export const isCreateBidValuesAsInitial = (value: CreateBidFormValues): boolean =>
    isEqual(value, initialCreateBidValues);

export const getInitialValuesForForm = (
    createBidValues: CreateBidFormValues,
    updatedValues?: CreateBidFormValues,
    isOpenFromSearchButton?: boolean,
): CreateBidFormValues => {
    if (isOpenFromSearchButton && updatedValues) {
        return {...initialCreateBidValues, ...updatedValues};
    }

    if (isOpenFromSearchButton && !updatedValues) {
        return initialCreateBidValues;
    }

    if (isCreateBidValuesAsInitial(createBidValues) && updatedValues) {
        return {...createBidValues, ...updatedValues};
    }

    return createBidValues;
};
