import React, {useEffect, useState} from 'react';
import {Row, Typography, Col, Button, Flex} from 'antd';
import {Field, useField, useFormikContext} from 'formik';
import {PlusOutlined} from '@ant-design/icons';
import Form from 'antd/lib/form';

import FormInput from 'components/ui/form/FormInput';

import SearchCustomerInput from 'core/entities/Customer/components/SearchCustomerInput';
import {SPECIAL_MARK} from 'core/entities/Customer/constants';
import {SpecialMarkType} from 'core/entities/Customer/types';

import {AddCustomerToBidFormValues} from 'pages/Bids/types';
import {QUOTE_SOURCE} from 'pages/Bids/constants';
import QuoteSourceSelect from 'pages/Bids/components/common/QuoteSourceSelect';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import validation from './validation';

const {Title} = Typography;

const CustomerInformationForm: React.FC<{
    openCustomerModal(value: boolean): React.Dispatch<React.SetStateAction<boolean>>;
}> = ({openCustomerModal}) => {
    const getName = getTypeFieldNameFactory<AddCustomerToBidFormValues>();
    const [quoteSourceField] = useField<AddCustomerToBidFormValues['quoteSource']>(getName('quoteSource'));
    const [customerField] = useField(getName('customer'));

    const [showQuoteSourceAnother, setShowQuoteSourceAnother] = React.useState(false);

    const {setFieldValue} = useFormikContext<AddCustomerToBidFormValues>();

    const [markCode, setCodeText] = useState<SpecialMarkType | null>(null);

    const isDNUCustomer = markCode === SPECIAL_MARK.dnu.code;
    const isLockedCustomer = markCode === SPECIAL_MARK.locked.code;

    useEffect(() => {
        if (quoteSourceField.value === QUOTE_SOURCE.another.code) {
            setShowQuoteSourceAnother(true);
        } else {
            setShowQuoteSourceAnother(false);
            setFieldValue(getName('quoteSourceAnother'), undefined);
        }
    }, [quoteSourceField.value]);

    useEffect(() => {
        if (customerField.value) {
            setCodeText(customerField.value?.mark || null);
        } else {
            setCodeText(null);
        }
    }, [customerField.value]);

    return (
        <Form>
            <Title level={5} style={{marginTop: '15px'}}>
                Customer Information
            </Title>

            {/* eslint-disable-next-line no-magic-numbers */}
            <Row gutter={[8, 0]}>
                {/* eslint-disable-next-line no-magic-numbers */}
                <Col span={showQuoteSourceAnother ? 15 : 24}>
                    <QuoteSourceSelect
                        name={getName('quoteSource')}
                        label="Quote Source:"
                        placeholder="Quote Source *"
                        validateSchema={validation}
                    />
                </Col>
                {showQuoteSourceAnother ? (
                    <Col span={9}>
                        <Field
                            name={getName('quoteSourceAnother')}
                            type="text"
                            placeholder="Source *"
                            as={FormInput}
                            validateSchema={validation}
                        />
                    </Col>
                ) : null}
            </Row>

            <Flex gap="small" align="flex-start">
                <Field
                    name={getName('customer')}
                    type="text"
                    label="Customer:"
                    placeholder="Customer *"
                    as={SearchCustomerInput}
                    required={false}
                    validateSchema={validation}
                />
                <Button
                    onClick={() => openCustomerModal(true)}
                    icon={<PlusOutlined />}
                    iconPosition="start"
                    style={{marginTop: '5px'}}
                />
            </Flex>
            <Typography style={{color: '#1677FF', marginLeft: '110px'}}>
                {isDNUCustomer
                    ? `Company can probably be in "Do not use list". You can check it with your safety representative.`
                    : null}
                {isLockedCustomer
                    ? 'Company can probably be locked. Be sure that you are not breaking the rules.'
                    : null}
            </Typography>
        </Form>
    );
};

export default CustomerInformationForm;
