import React, {useEffect, useState} from 'react';
import Text from 'antd/lib/typography/Text';
import Button from 'antd/lib/button';
import {CloseOutlined} from '@ant-design/icons/lib';

import {Notification} from 'core/entities/Notification/types';

import styles from '../../notificationList.module.scss';
import {getDifferenceTimeFromNow} from '../../utils';

import OpenBidButton from './components/OpenBidButton';
import OpenCustomerButton from './components/OpenCustomerButton';

interface OwnProps {
    notification: Notification;
    removeNotification: (id: string) => void;
}

const ONE_MINUTE_INTERVAL = 60000;

const ListItem: React.FC<OwnProps> = ({notification, removeNotification}) => {
    const [time, setTime] = useState<string>();

    useEffect(() => {
        setTime(getDifferenceTimeFromNow(notification.createdAt));
        const interval = setInterval(() => {
            setTime(getDifferenceTimeFromNow(notification.createdAt));
        }, ONE_MINUTE_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const isBidNotification = notification.type === 'bid-notification';

    return (
        <>
            <Text>{notification.message}</Text>
            <br />
            <Text>{time}</Text>
            <br />
            {isBidNotification ? (
                <OpenBidButton bidId={notification.entityID} />
            ) : (
                <OpenCustomerButton customerId={notification.entityID} />
            )}
            <Button
                onClick={() => removeNotification(notification.id as string)}
                danger
                type="link"
                className={styles.close_button}
                icon={<CloseOutlined />}
            />
        </>
    );
};

export default ListItem;
