import {UserSettings} from 'store/types';
import * as actionTypes from 'store/actions/actionTypes';

const defaultState: UserSettings = {};

export default function userSettings(
    // eslint-disable-next-line default-param-last
    state = defaultState,
    action: actionTypes.SetUserBidsPagePaginationNumber | actionTypes.SetUserInfo | actionTypes.ClearUserInfo,
): UserSettings {
    switch (action.type) {
        case actionTypes.SET_USER_BIDS_PAGE_PAGINATION_NUMBER: {
            return {
                ...state,
                bidsPagePaginationNumber: action.payload.pageNumber,
            };
        }

        case actionTypes.SET_USER_INFO: {
            const {email, password} = action.payload;
            return {
                ...state,
                bidEmail: email,
                bidPassword: password,
            };
        }

        case actionTypes.CLEAR_USER_INFO: {
            return {
                ...state,
                bidEmail: undefined,
                bidPassword: undefined,
            };
        }

        default:
            return state;
    }
}
