import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';

import {
    fetchNotifications,
    handleSound,
    removeNotification,
    clearNotifications,
} from 'widgets/Notification/actions/notificationsActions';

interface OwnProps {
    fetchNotifications: () => void;
    handleSound: (isSound: boolean) => void;
    removeNotification: (id: string) => void;
    clearNotifications: () => void;
}

export default function (): OwnProps {
    const dispatch = useDispatch<Dispatch<any>>();

    return {
        fetchNotifications: () => dispatch(fetchNotifications()),
        handleSound: (isSound: boolean) => dispatch(handleSound(isSound)),
        removeNotification: (id: string) => dispatch(removeNotification(id)),
        clearNotifications: () => dispatch(clearNotifications()),
    };
}
