import React from 'react';
import Button from 'antd/lib/button';

import useNotificationsWidgetActions from 'hooks/useFindBidByIdActions';
import useStateUserIsAdmin from 'hooks/useStateUserIsAdmin';

type OwnProps = {
    customerId: string;
};

const OpenCustomerButton: React.FC<OwnProps> = ({customerId}) => {
    const isAdmin = useStateUserIsAdmin();
    const {receivedCustomerById} = useNotificationsWidgetActions();

    if (!isAdmin) {
        return null;
    }

    return (
        <>
            <Button type="link" style={{padding: 0}} onClick={() => receivedCustomerById(customerId)}>
                Show the Customer
            </Button>
        </>
    );
};

export default OpenCustomerButton;
