import React from 'react';
import {FieldInputProps, useField} from 'formik';
import Radio from 'antd/lib/radio';
import Item from 'antd/lib/form/FormItem';

interface FormRadioProps extends FieldInputProps<string> {
    type: string | undefined;
    label: string | undefined;
    value: any;
    required: boolean;
}

const RadioInput = ({value, label, required, ...props}: FormRadioProps): JSX.Element | null => {
    const [field, meta] = useField(props);
    const items = value.map((option: string): JSX.Element => {
        return (
            <Radio key={option} value={option}>
                {option}
            </Radio>
        );
    });
    return (
        <Item label={label} required={required} help={meta.error} validateStatus={meta.error ? 'error' : ''}>
            <Radio.Group {...field}>{items}</Radio.Group>
        </Item>
    );
};

export default RadioInput;
