import isEmpty from 'lodash/isEmpty';

import {UserData} from 'core/entities/User/types';

import {PaginationValues} from 'store/types';
import * as websocketTypes from 'store/middlewares/websocket/actionTypes';

import {getPaginationFromResponse} from 'utils/getPagination';

import * as types from '../actionTypes';

export interface UsersState {
    users: UserData[];
    usersPagination: PaginationValues;
    isSearch: boolean;
    searchParams: string;
}

export const initialUsersPagination: PaginationValues = {
    pageSize: 10,
    currentPage: 1,
    allItemsCount: 0,
    allPagesCount: 0,
};

export const defaultState: UsersState = {
    users: [],
    usersPagination: initialUsersPagination,
    isSearch: false,
    searchParams: '',
};

export default function usersReducer(
    // eslint-disable-next-line default-param-last
    state = defaultState,
    action: types.UsersActionTypes | websocketTypes.WebSocketActionTypes,
): UsersState {
    switch (action.type) {
        case types.LIST_USERS_RECEIVED: {
            const {users, pagination} = action.payload;
            // eslint-disable-next-line
            const usersPagination = isEmpty(pagination)
                ? initialUsersPagination
                : getPaginationFromResponse(pagination);

            return {
                ...state,
                users,
                usersPagination,
                isSearch: false,
            };
        }

        case types.LIST_USERS_SEARCH: {
            const {users, pagination, searchParams} = action.payload;

            const usersPagination = isEmpty(pagination)
                ? initialUsersPagination
                : getPaginationFromResponse(pagination);

            return {
                ...state,
                users,
                isSearch: true,
                usersPagination,
                searchParams,
            };
        }

        case types.CLEAR_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: '',
            };

        case websocketTypes.WEB_SOCKET_RECEIVED_REMOVED_USER: {
            const {id: deleteId} = action.payload;

            const users = state.users.filter(({id}: UserData): boolean => id !== deleteId);
            return {
                ...state,
                users,
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_CREATED_USER: {
            if (state.isSearch) {
                return state;
            }
            const {newUser} = action.payload;

            const users = [newUser, ...state.users];

            return {
                ...state,
                users,
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_UPDATED_USER: {
            const {updatedUser} = action.payload;

            const users = state.users.reduce((userArray: UserData[], user: UserData) => {
                const userData = user.id === updatedUser.id ? updatedUser : user;
                userArray.push(userData);
                return userArray;
            }, []);

            return {
                ...state,
                users,
            };
        }

        default:
            return state;
    }
}
