import React from 'react';
import {Field} from 'formik';
import {ObjectSchema} from 'yup';

import CityLineInput from 'components/ui/form/CityLineInput';

type OwnProps = {
    name: string;
    validateSchema?: ObjectSchema<any>;
};

const CityLineInputField: React.FC<OwnProps> = (props) => {
    const {name} = props;

    return (
        <Field
            name={name}
            type="text"
            placeholder="enter city, state, zip, country"
            as={CityLineInput}
            validateSchema={props.validateSchema}
        />
    );
};

export default CityLineInputField;
