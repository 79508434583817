import {Modal} from 'antd';

import {Bid} from 'core/entities/Bid/types';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';

import {showModal, hideModal} from 'store/actions';

import {SearchParamsValues, CreateBidFormValues, AddNoteToBidFormValues} from '../types';
import {fileErrorMessage, fileErrorTitle, modalNames} from '../constants';
import {getCityLine} from '../utils';

import {createBidWithCheckOnExists, createBid, addNoteToBid, callBid} from './bidActions';

export function showAddBidNoteModal(data: Bid) {
    return function (dispatch: any) {
        dispatch(
            showModal({
                name: modalNames.addBidNoteModal,
                data,
                onSubmit: (values: AddNoteToBidFormValues) => dispatch(addNoteToBid(values)),
            }),
        );
    };
}

export function showCustomerModalsContainer({bidID, isCallABidModal}: {bidID: string; isCallABidModal: boolean}) {
    return function (dispatch: any) {
        dispatch(
            showModal({
                name: modalNames.customerModalsContainer,
                data: {bidID, isCallABidModal},
            }),
        );
    };
}

export function showCreateBidModal(searchParams: Omit<SearchParamsValues, 'searchByAddressGroups'>) {
    return function (dispatch: any) {
        const initialValues: Partial<CreateBidFormValues> = {
            pickupCityLine: getCityLine(searchParams.origin),
            deliveryCityLine: getCityLine(searchParams.destination),
        };
        const modalParams = {
            name: modalNames.createBidModal,
            data: {initialValues},
            onSubmit: (values: CreateBidFormValues) => {
                dispatch(createBidWithCheckOnExists(values));
            },
        };
        dispatch(showModal(modalParams));
    };
}

export function showCopyBidModal(bid: Bid) {
    return function (dispatch: any) {
        const initialValues = {
            ...bid,
            pickupCityLine: getCityLine(bid.pickupAddress),
            deliveryCityLine: getCityLine(bid.deliveryAddress),
        };

        const modalParams = {
            name: modalNames.createBidModal,
            data: {initialValues, isOpenFromCopy: true},
            onSubmit: (values: CreateBidFormValues) => {
                dispatch(createBidWithCheckOnExists(values));
            },
        };
        dispatch(showModal(modalParams));
    };
}

export function showFileErrorModal() {
    return function (dispatch: any) {
        function error(): void {
            Modal.error({
                title: fileErrorTitle,
                content: fileErrorMessage,
            });
        }
        dispatch(error);
    };
}

export function showSameBidFoundModal(
    bidValuesForCreate: CreateBidFormValues,
    bids: Bid[],
    confirmedLines: ConfirmedLine[],
) {
    return function (dispatch: any) {
        const modalParams = {
            name: modalNames.sameBidFoundModal,
            data: {bids, confirmedLines},
            handlers: {
                onCallSameBid: (id: string) => {
                    dispatch(callBid(id));
                    dispatch(hideModal());
                },
                onCreateSameBid: () => {
                    dispatch(createBid(bidValuesForCreate));
                    dispatch(hideModal());
                },
            },
        };
        dispatch(showModal(modalParams));
    };
}
