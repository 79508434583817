import React from 'react';
import Text from 'antd/lib/typography/Text';

import {Bid} from 'core/entities/Bid/types';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';

import styles from './modalTitle.module.scss';
import {getTitle} from './selectors';

interface OwnProps {
    bids: Bid[];
    confirmedLines: ConfirmedLine[];
}

const ModalTitle = ({bids, confirmedLines}: OwnProps): JSX.Element => {
    return (
        <div className={styles.title}>
            <Text strong={true}>{getTitle(bids, confirmedLines)}</Text>
        </div>
    );
};

export default ModalTitle;
