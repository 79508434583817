import React from 'react';
import {Button} from 'antd';

type OwnProps = {
    handlePress: () => void;
    title: string;
};

const ButtonLink: React.FC<OwnProps> = ({handlePress, title}) => <Button onClick={handlePress}>{title}</Button>;

export default ButtonLink;
