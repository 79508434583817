import React from 'react';
import {useField, FieldHookConfig} from 'formik';
import Item from 'antd/lib/form/FormItem';
import {Select as AntdSelect} from 'antd';
import {ObjectSchema} from 'yup';

import validateField from 'utils/validateField';

export type SelectProps = {
    label?: string;
    placeholder: string;
    required?: boolean;
    size?: 'large' | 'middle' | 'small';
    options: {label: string; value: string}[];
    validateSchema?: ObjectSchema<any>;
    disabled?: boolean;
} & FieldHookConfig<any>;

const Select: React.FC<SelectProps> = (props: SelectProps) => {
    const {
        label,
        placeholder,
        required = false,
        size = 'middle',
        options,
        validateSchema,
        disabled,
        ...fieldProps
    } = props;
    const [field, meta, helpers] = useField(fieldProps);

    const handleChange = (value: string) => {
        const selectedOption = options.find((option) => option.value === value);

        helpers.setValue(selectedOption?.value);

        validateField({
            name: field.name,
            value: selectedOption?.value,
            setError: helpers.setError,
            validateSchema,
            errors: meta.error,
        });
    };

    return (
        <Item label={label} required={required} help={meta.error} validateStatus={meta.error ? 'error' : ''}>
            <AntdSelect
                status={meta.error ? 'error' : ''}
                size={size}
                onChange={handleChange}
                options={options}
                placeholder={placeholder}
                style={{width: '100%', fontWeight: 400}}
                value={field.value}
                disabled={disabled}
            />
        </Item>
    );
};

export default Select;
