import React, {useEffect, useState} from 'react';

import {CustomerFormValues} from 'core/entities/Customer/types';

import {AddCustomerToBidFormValues} from 'pages/Bids/types';
import useBidActions from 'pages/Bids/hooks/useBidActions';
import CreateCustomerModal from 'pages/Customers/components/modals/AddCustomerModal';

import AddCustomerInfoModal from '../AddCustomerInfoModal';

type OwnProps = {
    data: {bidID: string; isCallABidModal: boolean};
};

const CustomerModalsContainer: React.FC<OwnProps> = (props) => {
    const {data} = props;
    const {createCustomer, addCustomerToBid} = useBidActions();
    const [isOpenCustomerModal, openCustomerModal] = useState(true);
    const [isCreateCustomerModalVisible, setCreateCustomerModalVisible] = useState(false);
    const [customer, setCustomer] = useState<CustomerFormValues>();

    useEffect(() => {
        if (data.isCallABidModal) {
            openCustomerModal(true);
        }
    }, [data]);

    const handleCreateCustomerSubmit = (values: CustomerFormValues) => {
        createCustomer(values);
        setCustomer(values);
        setCreateCustomerModalVisible(false);
    };

    const handleAddCustomerToBidSubmit = (values: AddCustomerToBidFormValues) => {
        addCustomerToBid(values, data.bidID);
        openCustomerModal(false);
    };

    return (
        <>
            {isOpenCustomerModal ? (
                <AddCustomerInfoModal
                    data={data}
                    onSubmit={handleAddCustomerToBidSubmit}
                    onClose={() => openCustomerModal(false)}
                    openCustomerModal={setCreateCustomerModalVisible}
                    customer={customer}
                />
            ) : null}

            {isCreateCustomerModalVisible ? (
                <CreateCustomerModal
                    onSubmit={handleCreateCustomerSubmit}
                    onClose={() => setCreateCustomerModalVisible(false)}
                />
            ) : null}
        </>
    );
};

export default CustomerModalsContainer;
