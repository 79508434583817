import {modalNames} from '../../constants';

import AddCustomerModal from './AddCustomerModal';
import DeleteCustomerModal from './DeleteCustomerModal';

const modalMap = {
    [modalNames.createCustomer]: AddCustomerModal,
    [modalNames.deleteCustomer]: DeleteCustomerModal,
};

export default modalMap;
