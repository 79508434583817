import React, {useState} from 'react';
import head from 'lodash/head';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import {Field, Formik} from 'formik';
import {FormikHelpers} from 'formik/dist/types';

import NoteFormTextArea from 'components/ui/form/NoteFormTextArea';

import {TEXT_FIELD_LENGTH} from 'pages/Bids/constants';

import NoteFileUploader from '../../common/NoteFileUploader';
import CityLine from '../../common/CityLine';

const {Item} = Form;

interface NoteInterface {
    text: string;
    files: File[];
}

const AddNoteModal = ({onClose, data, onSubmit}: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const initialValues: NoteInterface = {
        text: '',
        files: [],
    };

    const handleCancel = (resetForm: any) => {
        setLoading(false);
        onClose();
        resetForm();
    };

    const handleOk = (value: NoteInterface, {resetForm}: FormikHelpers<NoteInterface>): void => {
        setLoading(true);
        onSubmit({
            ...value,
            bidID: data.id,
        });
        resetForm();
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleOk} validateOnChange={false}>
            {({handleSubmit, resetForm, values}) => {
                const newText = values.text;
                const attachedFile = head(values.files);
                const isSubmitDisabled = !newText && !attachedFile;
                return (
                    <Modal
                        className="note-modal-body"
                        visible={true}
                        closable={false}
                        title="New Bid Note"
                        onOk={() => handleSubmit()}
                        onCancel={() => handleCancel(resetForm)}
                        footer={[
                            <Button key="back" onClick={() => handleCancel(resetForm)}>
                                Cancel
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={loading}
                                onClick={() => handleSubmit()}
                                disabled={isSubmitDisabled}
                            >
                                Add Note
                            </Button>,
                        ]}
                    >
                        <Form>
                            <Item label="Bid">
                                <Text>
                                    <CityLine address={data.pickupAddress} /> -{' '}
                                    <CityLine address={data.deliveryAddress} />, ${data.rate}
                                </Text>
                            </Item>
                            <Field
                                name="text"
                                type="text"
                                label="Note"
                                placeholder="Enter note"
                                maxLength={TEXT_FIELD_LENGTH}
                                as={NoteFormTextArea}
                            />
                            <Field name="files" type="file" label="File" as={NoteFileUploader} />
                        </Form>
                    </Modal>
                );
            }}
        </Formik>
    );
};

export default AddNoteModal;
