import React from 'react';
import {useSelector} from 'react-redux';
import {LoadingOutlined} from '@ant-design/icons';

import {getShowLoadSpinner} from 'store/reducers/appData/selectors';

import styles from './loader.module.scss';

const LoaderSpinner = (): JSX.Element | null => {
    const showLoadSpinner = useSelector(getShowLoadSpinner);
    return showLoadSpinner ? (
        <div className={styles.spinner}>
            <LoadingOutlined />
        </div>
    ) : null;
};

export default LoaderSpinner;
