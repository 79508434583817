import isPlainObject from 'lodash/isPlainObject';

import {Attachment} from 'core/entities/Bid/types';

/**
 * Returns the path to the file using its URL and name.
 * @param {object} file
 */

export default (file: Attachment): string => {
    if (!file || !isPlainObject(file) || !file.url) {
        return '';
    }
    return file.url;
};
