import {Dispatch} from 'redux';
import {Modal} from 'antd';

export const showErrorModal = () => {
    return (dispatch: Dispatch<any>) => {
        const error = (): void => {
            Modal.error({
                title: 'Error',
                content: 'An error occurred, please try again later.',
            });
        };
        dispatch(error);
    };
};

export const showSuccessModal = (entityText: string) => {
    return (dispatch: Dispatch<any>) => {
        const success = (): void => {
            Modal.success({
                title: 'Success',
                content: `The ${entityText} has been successfully added.`,
            });
        };
        dispatch(success);
    };
};
