import trim from 'lodash/trim';
import toUpper from 'lodash/toUpper';

import {BillingAddress} from 'core/entities/Customer/types';

export const isEmptyBillingAddress = (address: Partial<BillingAddress>): boolean => {
    return !address.addressLine && !address.city && !address.state && !address.zip && !address.country;
};

export const parseStringToCityStateZipCountry = (value?: string): Partial<BillingAddress> | undefined => {
    if (!value) {
        return;
    }

    const [city, state, zip, country] = value.split(',').map(trim);

    return {city, state: toUpper(state), zip, country: toUpper(country)};
};

export const cityStateZipCountryToString = (value?: Partial<BillingAddress>): string => {
    if (!value) {
        return '';
    }

    const {city = '', state = '', zip = '', country = ''} = value;

    return [city, toUpper(state), zip, toUpper(country)].filter(Boolean).join(', ');
};
