import React from 'react';
import {Typography} from 'antd';
import {Field} from 'formik';
import Form from 'antd/lib/form';

import FormInput from 'components/ui/form/FormInput';

import {CustomerFormValues} from 'core/entities/Customer/types';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import CityLineInputField from '../CityLineInputField';

const {Title} = Typography;
const {Item} = Form;

const getName = getTypeFieldNameFactory<CustomerFormValues>();

const BillingAddress: React.FC = () => {
    return (
        <>
            <Title level={5} style={{marginTop: '15px'}}>
                Billing Address
            </Title>
            <Field
                name={getName('address')}
                type="input"
                label="Address"
                placeholder="Enter Address"
                as={FormInput}
                required={false}
                isNeedIgnoreSpecialSymbols={true}
            />
            <Item label="Location:">
                <CityLineInputField name={getName('location')} />
            </Item>
        </>
    );
};

export default BillingAddress;
