import {DateTime} from 'luxon';

import {FULL_DATE_FORMAT, TIME_ZONE, REPORT_DATE_FORMAT, REPORT_DATE_FORMAT_REQUEST} from 'utils/date/constants';

export const getDateConvertedToDefaultTz = (
    date: Date | string,
    options: {withFormat: boolean} = {withFormat: true},
): string => {
    const convertedDate = DateTime.fromISO(date.toString(), {zone: 'utc'}).setZone(TIME_ZONE);
    return options.withFormat ? convertedDate.toFormat(FULL_DATE_FORMAT) : convertedDate.toISO() || '';
};

export const getNowWithDefaultTimeZone = (): DateTime => DateTime.local();

export const convertToStartDay = (date: string): string => {
    const updatedDate = DateTime.fromFormat(date, 'MM/dd/yy', {zone: TIME_ZONE}).startOf('day');

    return updatedDate.toISO() || '';
};

export const convertToEndDay = (date: string): string => {
    const updatedDate = DateTime.fromFormat(date, 'MM/dd/yy', {zone: TIME_ZONE}).endOf('day');
    return updatedDate.toISO() || '';
};

export const convertReportDateToTimezone = (date: string): string => {
    return DateTime.fromISO(date, {zone: TIME_ZONE}).toFormat(REPORT_DATE_FORMAT);
};

export const convertStringToDateTime = (dateString: string): DateTime => {
    return DateTime.fromISO(dateString, {zone: TIME_ZONE});
};

export const convertReportDateToRequest = (date: string): string => {
    return DateTime.fromISO(date, {zone: TIME_ZONE}).toFormat(REPORT_DATE_FORMAT_REQUEST);
};
