import React, {useEffect, useState} from 'react';
import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/grid/col';
import Text from 'antd/lib/typography/Text';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Title from 'antd/lib/typography/Title';
import {CaretDownOutlined} from '@ant-design/icons/lib';

import useNotificationsActions from '../../../../hooks/useNotificationsActions';

import styles from './notificationListHeader.module.scss';

const NotificationListHeader = (): JSX.Element => {
    const {handleSound, clearNotifications} = useNotificationsActions();

    const [isRotate, setIsRotate] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState<string>('Sound On');

    const rotateAngle = 180;
    const soundOn = 'Sound On';
    const soundOff = 'Sound Off';

    useEffect(() => {
        setIsRotate(false);
        handleSound(buttonText === soundOn);
    }, [buttonText]);

    const menuItems = [
        {
            key: soundOn,
            label: soundOn,
            onClick: () => setButtonText(soundOn),
        },
        {
            key: soundOff,
            label: soundOff,
            onClick: () => setButtonText(soundOff),
        },
    ];

    return (
        <Row className={styles.wrap}>
            <Col span={12}>
                <Title level={4}>Notification</Title>
            </Col>
            <Col span={6}>
                <Button onClick={clearNotifications} className={styles.clear_text} type="link">
                    clear all
                </Button>
            </Col>
            <Col span={6}>
                <Dropdown menu={{items: menuItems}} onOpenChange={() => setIsRotate(!isRotate)} trigger={['click']}>
                    <Button>
                        <Text>{buttonText}</Text>
                        <CaretDownOutlined rotate={isRotate ? rotateAngle : 0} />
                    </Button>
                </Dropdown>
            </Col>
        </Row>
    );
};

export default NotificationListHeader;
