import {UploadFile} from 'antd/lib/upload/interface';
import isEmpty from 'lodash/isEmpty';

import {UserData} from 'core/entities/User/types';
import {Bid, Note, Attachment, Address, BidUserData, CallUsersData} from 'core/entities/Bid/types';

import {getNowWithDefaultTimeZone, getDateConvertedToDefaultTz, convertStringToDateTime} from 'services/date';

import getFilePath from 'utils/getFilePath';

import {deletedUserPlaceholder} from '../../../constants';
import {BidRow, ExpandDataInterface, ExpandNoteInterface} from '../types';
import {isStateValid} from '../../../utils';

type UserCreatedBid = {
    name: string;
    company: string;
};

const createFileExpandData = (file: Attachment): UploadFile[] => {
    return [
        {
            url: getFilePath(file),
            name: file.originalName,
            uid: `-1`,
            status: 'done',
            type: file.mimeType,
            size: file.size,
        },
    ];
};

export const getClearAddressValue = (value: string): string => {
    const arrayString: string[] = value.match(/[^\W][a-zA-Z_'`.-]*/gi) || [];
    return arrayString.join(' ');
};

export const getAddress = (value: string): Address => {
    const clearValue = value ? getClearAddressValue(value) : '';
    const isState = isStateValid(clearValue);
    const city: string = !isState ? clearValue : '';
    const state: string = isState ? clearValue.toUpperCase() : '';
    return {
        city,
        state,
    };
};

export const getBidUserData = (userCreated: BidUserData, currentUser: UserData): UserCreatedBid => {
    if (!userCreated) {
        return {
            company: deletedUserPlaceholder,
            name: deletedUserPlaceholder,
        };
    }

    if (userCreated.company === currentUser.company && userCreated.teamCode === currentUser.teamCode) {
        return {
            company: userCreated.company,
            name: `${userCreated.firstName} ${userCreated.lastName}`,
        };
    }

    if (userCreated.company === currentUser.company && userCreated.teamCode !== currentUser.teamCode) {
        return {
            company: `${userCreated.company} / ${userCreated.teamCode}`,
            name: `${userCreated.firstName} ${userCreated.lastName}`,
        };
    }

    return {
        company: userCreated.teamCode as string,
        name: userCreated.userCode as string,
    };
};

const getBidLastUpdateTime = (bid: Bid): string => bid.updatedAt || bid.createdAt;

const createBidExpandData = (bid: Bid, currentUser: UserData): ExpandDataInterface[] => {
    const bidNotes = bid.notes || [];
    const reversedNotes = [...bidNotes].reverse();
    const expandArray: ExpandDataInterface[] = reversedNotes.map((description: Note): ExpandDataInterface => {
        const userCreatedBid = getBidUserData(description.createdBy, currentUser as UserData);
        return {
            key: description.id,
            date: getDateConvertedToDefaultTz(description.createdAt),
            company: userCreatedBid?.company,
            user: userCreatedBid?.name,
            customerName: description.payload?.customerName || '',
            mark: description.payload?.customerMark || undefined,
            quoteSource: description.payload?.quoteSource || '',
            note: {
                noteText: description.text,
                attachment: description.attachment && createFileExpandData(description.attachment),
                bidId: bid.id,
                noteId: description.id,
            },
        };
    });
    return expandArray;
};

export const getBidArchiveData = (
    bid: Bid,
    bidExpireDuration: number,
): {isArchived: boolean; remainingTimeToArchiving: undefined | number} => {
    if (bid.isArchived) {
        return {isArchived: true, remainingTimeToArchiving: undefined};
    }
    const bidLastUpdateTime = getBidLastUpdateTime(bid);
    const dateNow = getNowWithDefaultTimeZone();
    const dateNowInMilliseconds = dateNow.toMillis();
    const lastUpdateTime = convertStringToDateTime(getDateConvertedToDefaultTz(bidLastUpdateTime, {withFormat: false}));
    const lastUpdateTimeInMilliseconds = lastUpdateTime.toMillis();
    const timeBetweenBidLastUpdateAndNow = dateNowInMilliseconds - lastUpdateTimeInMilliseconds;
    const isArchived = timeBetweenBidLastUpdateAndNow > bidExpireDuration;
    const remainingMillisecondsBeforeArchiving = bidExpireDuration - timeBetweenBidLastUpdateAndNow;
    return {isArchived, remainingTimeToArchiving: isArchived ? undefined : remainingMillisecondsBeforeArchiving};
};

export const getUsersId = (users: CallUsersData[] | undefined): string[] => {
    if (isEmpty(users) || !users) {
        return [];
    }

    return users.map((item) => item.user.id as string);
};

export const getBidsRows = ({
    data,
    currentUser,
    modalActions,
    bidExpireDuration,
}: {
    data: Bid[];
    currentUser: UserData | undefined;
    modalActions: {showAddBidNoteModal(bid: Bid): void; showCopyBidModal(bid: Bid): void};
    bidExpireDuration: number;
}): BidRow[] => {
    const rowArray: BidRow[] = data.map((bid: Bid): BidRow => {
        const userCreatedBid = getBidUserData(bid.createdBy, currentUser as UserData);
        const {isArchived, remainingTimeToArchiving} = getBidArchiveData(bid, bidExpireDuration);
        const date = getDateConvertedToDefaultTz(getBidLastUpdateTime(bid));
        const createdAt = getDateConvertedToDefaultTz(bid.createdAt);
        const rate = bid.rate?.toLocaleString();
        const description: ExpandDataInterface[] = createBidExpandData(bid, currentUser as UserData);
        const lastNote: ExpandNoteInterface | undefined = description.length ? description[0].note : undefined;
        const usersId = getUsersId(bid.calledByUsers);

        return {
            key: bid.id,
            pickup: {
                address: bid.pickupAddress,
                amount: bid.pickupAmount,
            },
            delivery: {
                address: bid.deliveryAddress,
                amount: bid.deliveryAmount,
            },
            truckType: bid.truckType,
            rate,
            createdAt,
            lastUpdate: {
                date: date || createdAt,
                timer: remainingTimeToArchiving,
                bidId: bid.id,
            },
            company: userCreatedBid.company,
            user: userCreatedBid.name,
            calledByUserIds: {
                isArchived,
                bidId: bid.id,
                usersId,
                createdByUserId: bid?.createdBy?.id as string,
            },
            notes: {
                isArchived,
                count: bid.notes?.length,
                showAddBidNoteModal: () => modalActions.showAddBidNoteModal(bid),
                showCopyBidModal: () => modalActions.showCopyBidModal(bid),
                lastNote,
            },
            description,
            archived: isArchived,
            customer: bid.customerInfo?.customer,
            quoteSource: bid.customerInfo?.quoteSource,
        };
    });
    return rowArray;
};
