import React, {useEffect} from 'react';
import {notification} from 'antd';
import useSound from 'use-sound';

import useNotificationsWidgetActions from 'hooks/useFindBidByIdActions';
import useStateUserIsAdmin from 'hooks/useStateUserIsAdmin';

import notificationSound from 'widgets/Notification/sound/notification.mp3';

import {NotificationMessage} from './types';
import useStateNotification from './hooks/useStateNotification';
import useStateIsSoundNotification from './hooks/useStateIsSoundNotification';
import ButtonLink from './components/ButtonLink';

const Notification = (): null => {
    const notificationData: NotificationMessage = useStateNotification();
    const isSound: boolean = useStateIsSoundNotification();
    const isAdmin = useStateUserIsAdmin();
    const {receivedBidById, receivedCustomerById} = useNotificationsWidgetActions();
    const [play] = useSound(notificationSound);
    useEffect(() => {
        if (notificationData.message.length) {
            const isBidNotification = notificationData.type === 'bid-notification';
            const isCustomerNotification = notificationData.type === 'customer-notification';
            notification.open({
                description: notificationData.description,
                message: notificationData.message,
                btn: (
                    <>
                        {isBidNotification ? (
                            <ButtonLink
                                title="Show the Bid"
                                handlePress={() => receivedBidById(notificationData.entityID as string)}
                            />
                        ) : null}
                        {isCustomerNotification && isAdmin ? (
                            <ButtonLink
                                title="Show the Customer"
                                handlePress={() => receivedCustomerById(notificationData.entityID as string)}
                            />
                        ) : null}
                    </>
                ),
            });
            if (isSound) {
                play();
            }
        }
    }, [notificationData]);
    return null;
};

export default Notification;
