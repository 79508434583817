import {modalNames} from '../../constants';

import AddNoteModal from './AddNoteModal';
import CreateBidModal from './CreateBidModal';
import SameBidFoundModal from './SameBidFoundModal';
import CustomerModalsContainer from './CustomerModalsContainer';

const modalMap = {
    [modalNames.addBidNoteModal]: AddNoteModal,
    [modalNames.createBidModal]: CreateBidModal,
    [modalNames.sameBidFoundModal]: SameBidFoundModal,
    [modalNames.customerModalsContainer]: CustomerModalsContainer,
};

export default modalMap;
