import {Notification} from 'core/entities/Notification/types';

import {getDateConvertedToDefaultTz} from 'services/date';

import * as websocketTypes from 'store/middlewares/websocket/actionTypes';

import {NotificationState} from '../types';
import * as notificationTypes from '../actionTypes';

const defaultState: NotificationState = {
    data: {
        message: '',
        type: 'bid-notification',
        description: '',
        entityID: '',
    },
    notifications: [],
    isSound: true,
};

export default function notificationReducer(
    // eslint-disable-next-line default-param-last
    state = defaultState,
    action: websocketTypes.WebSocketActionTypes | notificationTypes.NotificationActionTypes,
): NotificationState {
    switch (action.type) {
        case websocketTypes.WEB_SOCKET_RECEIVED_NOTIFICATION: {
            const {notification} = action.payload;

            const date = getDateConvertedToDefaultTz(notification?.createdAt || new Date());

            // temp for debug return current state
            return {
                ...state,
                notifications: [notification, ...state.notifications],
                data: {
                    type: notification.type,
                    message: notification.title,
                    description: [notification.message, date].join('\n'),
                    entityID: notification.entityID,
                },
            };
        }

        case notificationTypes.RECEIVED_NOTIFICATIONS: {
            const {notifications} = action.payload;

            return {
                ...state,
                notifications,
            };
        }

        case notificationTypes.CHANGE_SOUND_NOTIFICATION: {
            const {isSound} = action.payload;

            return {
                ...state,
                isSound,
            };
        }

        case notificationTypes.REMOVE_NOTIFICATION: {
            const {id} = action.payload;

            const notifications = state.notifications.filter(
                ({id: notificationId}: Notification): boolean => notificationId !== id,
            );

            return {
                ...state,
                notifications,
            };
        }

        case notificationTypes.CLEAR_NOTIFICATIONS: {
            return {
                ...state,
                notifications: [],
            };
        }

        default:
            return state;
    }
}
