import React from 'react';
import {AppSettingsContext} from 'context/appSettings';

import useAppSettingsSetup from 'hooks/appSettings/useAppSettingsSetup';

const AppContext: React.FC<{children: React.ReactNode}> = ({children}) => {
    const appSettings = useAppSettingsSetup();
    return <AppSettingsContext.Provider value={appSettings}>{children}</AppSettingsContext.Provider>;
};

export default AppContext;
