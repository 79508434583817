import {AxiosResponse} from 'axios';
import head from 'lodash/head';
import trim from 'lodash/trim';
import omit from 'lodash/omit';

import {Bid} from 'core/entities/Bid/types';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';
import {CustomerFormValues} from 'core/entities/Customer/types';

import restApi from 'services/api';

import {PaginationValues} from 'store/types';

import {getPaginationFromResponse} from 'utils/getPagination';

import {
    CreateBidFormValues,
    AddNoteToBidFormValues,
    SearchParamsValues,
    Filters,
    CustomerToBidParams,
    ConfirmedLineParams,
} from '../types';

interface SearchResult<T> {
    items: T[];
    pagination: PaginationValues;
}

interface ConfirmedLineResult<T> {
    item: T;
}

interface FullAddressSearchParams {
    searchByAddressGroups: boolean;
    pickupAddress: {city: string; state: string};
    deliveryAddress: {city: string; state: string};
}

interface StatesSearchParams {
    stateFrom: string;
    stateTo: string;
}

interface SearchByRouteParams {
    route?: string;
    page?: number;
    perPage?: number;
}

interface SearchByCustomerParams {
    companyName?: string;
    page?: number;
    perPage?: number;
}

interface CustomFile extends File {
    uploadedData: any;
}

const getPaginationValue = (pagination: PaginationValues): SearchByRouteParams => ({
    page: pagination.currentPage,
    perPage: pagination.pageSize,
});

const getCustomerFromSearchParams = (companyName: string, pagination: PaginationValues): SearchByCustomerParams => {
    const {page, perPage} = getPaginationValue(pagination);
    return {
        companyName,
        page,
        perPage,
    };
};

const getBidAddressFromCityLine = (cityLine: string): {city: string; state: string} => {
    const [city, state] = cityLine.split(',');
    return {city, state: trim(state)};
};

export const fetchBids = (pagination: PaginationValues): Promise<SearchResult<Bid>> => {
    const url = '/bids';
    return restApi
        .get(url, {
            params: getPaginationValue(pagination),
        })
        .then((response) => {
            return {items: response.data.bids, pagination: getPaginationFromResponse(response.data.pagination)};
        })
        .catch((error) => {
            console.error('Error on search bids: ', error);
            return {items: [], pagination: {}};
        });
};

export const searchBidsByMatchingAddress = (
    searchParams: SearchParamsValues,
    pagination: PaginationValues,
): Promise<SearchResult<Bid>> => {
    const getParams = {
        params: {
            search: searchParams.searchValue ? searchParams.searchValue : undefined,
            page: pagination.currentPage,
            perPage: pagination.pageSize,
        },
    };
    return restApi
        .get('/bids', getParams)
        .then((response) => {
            return {items: response.data.bids, pagination: getPaginationFromResponse(response.data.pagination)};
        })
        .catch((error) => {
            console.error('Error on search bids: ', error);
            return {items: [], pagination: {}};
        });
};

export const searchBidsByFullAddress = (
    searchParams: SearchParamsValues,
    pagination: PaginationValues,
): Promise<SearchResult<Bid>> => {
    const postBodyParams: FullAddressSearchParams = {
        searchByAddressGroups: searchParams.searchByAddressGroups,
        pickupAddress: searchParams.origin,
        deliveryAddress: searchParams.destination,
    };
    const queryParams = {params: getPaginationValue(pagination)};
    return restApi
        .post('/bids/route', postBodyParams, queryParams)
        .then((response) => {
            return {items: response.data.bids, pagination: getPaginationFromResponse(response.data.pagination)};
        })
        .catch((error) => {
            console.error('Error on search bids: ', error);
            return {items: [], pagination: {}};
        });
};

export const searchBidsByStates = (
    searchParams: SearchParamsValues,
    pagination: PaginationValues,
): Promise<SearchResult<Bid>> => {
    const postBodyParams: StatesSearchParams = {
        stateFrom: searchParams.origin.state,
        stateTo: searchParams.destination.state,
    };
    const queryParams = {params: getPaginationValue(pagination)};
    return restApi
        .post('/bids/states', postBodyParams, queryParams)
        .then((response) => {
            return {items: response.data.bids, pagination: getPaginationFromResponse(response.data.pagination)};
        })
        .catch((error) => {
            console.error('Error on search bids by states: ', error);
            return {items: [], pagination: {}};
        });
};

export const searchConfirmedLinesByMatchingAddress = (
    searchParams: SearchParamsValues,
    pagination: PaginationValues,
): Promise<SearchResult<Bid>> => {
    const getParams = {
        params: {
            search: searchParams.searchValue ? searchParams.searchValue : undefined,
            page: pagination.currentPage,
            perPage: pagination.pageSize,
        },
    };
    return restApi
        .get('/confirmed-lines', getParams)
        .then((response) => {
            return {
                items: response.data.confirmedLines,
                pagination: getPaginationFromResponse(response.data.pagination),
            };
        })
        .catch((error) => {
            console.error('Error on search confirmed lines: ', error);
            return {items: [], pagination: {}};
        });
};

export const searchConfirmedLinesByStates = (
    searchParams: SearchParamsValues,
    pagination: PaginationValues,
): Promise<SearchResult<Bid>> => {
    const postBodyParams: StatesSearchParams = {
        stateFrom: searchParams.origin.state,
        stateTo: searchParams.destination.state,
    };
    const queryParams = {params: getPaginationValue(pagination)};
    return restApi
        .post('/confirmed-lines/states', postBodyParams, queryParams)
        .then((response) => {
            return {
                items: response.data.confirmedLines,
                pagination: getPaginationFromResponse(response.data.pagination),
            };
        })
        .catch((error) => {
            console.error('Error on search confirmed lines: ', error);
            return {items: [], pagination: {}};
        });
};

export const searchConfirmedLinesByRoute = (
    searchParams: SearchParamsValues,
    pagination: PaginationValues,
): Promise<SearchResult<any>> => {
    const postBodyParams: FullAddressSearchParams = {
        searchByAddressGroups: searchParams.searchByAddressGroups,
        pickupAddress: searchParams.origin,
        deliveryAddress: searchParams.destination,
    };
    const queryParams = {params: getPaginationValue(pagination)};

    return restApi
        .post('/confirmed-lines/route', postBodyParams, queryParams)
        .then((response) => {
            return {
                items: response.data.confirmedLines,
                pagination: getPaginationFromResponse(response.data.pagination),
            };
        })
        .catch((error) => {
            console.error('Error on search confirmed lines: ', error);
            return {items: [], pagination: {}};
        });
};

export const searchSameBid = (bidData: CreateBidFormValues): Promise<any> => {
    const params = {
        pickupAddress: getBidAddressFromCityLine(bidData.pickupCityLine),
        deliveryAddress: getBidAddressFromCityLine(bidData.deliveryCityLine),
        rate: bidData.rate,
    };
    return restApi.post('/bids/created-recently', params);
};

export const postBid = (data: CreateBidFormValues): Promise<AxiosResponse> => {
    const uploadedFile = head(data.files) as CustomFile | undefined;
    const pickupAddress = getBidAddressFromCityLine(data.pickupCityLine);
    const deliveryAddress = getBidAddressFromCityLine(data.deliveryCityLine);
    const formData = new FormData();
    formData.append('pickupCity', pickupAddress.city);
    formData.append('pickupState', pickupAddress.state);
    formData.append('deliveryCity', deliveryAddress.city);
    formData.append('deliveryState', deliveryAddress.state);
    formData.append('pickupAmount', String(data.pickupAmount));
    formData.append('deliveryAmount', String(data.deliveryAmount));
    formData.append('truckType', String(data.truckType));
    formData.append('noteText', data.note as string);
    formData.append('rate', String(data.rate));
    if (uploadedFile) {
        formData.append('attachment', uploadedFile.uploadedData);
    }
    return restApi.post('/bid', formData);
};

export const postBidNote = (data: AddNoteToBidFormValues): Promise<AxiosResponse> => {
    const url = `/bid/${data.bidID}/note`;

    const uploadedFile: File = head(data.files)?.uploadedData;
    const formData: FormData = new FormData();
    formData.append('text', data.text as string);
    formData.append('attachment', uploadedFile);
    return restApi.post(url, formData);
};

export const fetchConfirmedLines = (pagination: PaginationValues): Promise<SearchResult<ConfirmedLine>> => {
    const url = '/confirmed-lines';
    return restApi
        .get(url, {
            params: getPaginationValue(pagination),
        })
        .then((response) => {
            return {
                items: response.data.confirmedLines,
                pagination: getPaginationFromResponse(response.data.pagination),
            };
        })
        .catch((error) => {
            console.error('Error on fetch confirmed lines: ', error);
            return {items: [], pagination: {}};
        });
};

export const postConfirmedLine = (confirmedLine: ConfirmedLineParams): Promise<ConfirmedLineResult<ConfirmedLine>> => {
    const url = '/confirmed-lines';
    return restApi.post(url, confirmedLine).then((response) => {
        return {
            item: response.data.confirmedLine,
        };
    });
};

export const updateConfirmedLine = (
    confirmedLine: ConfirmedLineParams,
): Promise<ConfirmedLineResult<ConfirmedLine>> => {
    const url = `/confirmed-lines/${confirmedLine.id}`;
    const queryParams = omit(confirmedLine, ['id', 'key']);

    return restApi.put(url, queryParams).then((response) => {
        return {
            item: response.data.confirmedLine,
        };
    });
};

export const removeConfirmedLine = (id: string): Promise<ConfirmedLineResult<string>> => {
    const url = '/confirmed-lines';

    return restApi
        .delete(url, {
            params: {
                id,
            },
        })
        .then((response) => {
            return {
                item: response.data.confirmedLineId,
            };
        });
};

export const callBid = (bidId: string): Promise<AxiosResponse> => {
    const url = `/bid/${bidId}/call`;
    return restApi.put(url);
};

export const recallBid = (bidId: string): Promise<AxiosResponse> => {
    const url = `/bid/${bidId}/recall`;
    return restApi.put(url);
};

export const fetchFilteredBids = (filters: Filters, pagination: PaginationValues): Promise<SearchResult<Bid>> => {
    const url = `/bids/search`;
    return restApi
        .post(url, filters, {
            params: {
                page: pagination.currentPage,
                perPage: pagination.pageSize,
            },
        })
        .then((response) => {
            return {items: response.data.bids, pagination: getPaginationFromResponse(response.data.pagination)};
        })
        .catch((error) => {
            console.error('Error on filter bids: ', error);
            return {items: [], pagination: {}};
        });
};

export const postCustomerToBid = (data: CustomerToBidParams, bidID: string): Promise<AxiosResponse> => {
    const url = `/bid/${bidID}/pending-update/complete`;
    return restApi.put(url, data);
};

export const createCustomer = (data: CustomerFormValues): Promise<AxiosResponse> => {
    const url = `/customers`;
    return restApi.post(url, data);
};

export const getCustomer = (companyName: string, pagination: PaginationValues): Promise<AxiosResponse> => {
    const url = `/customers`;
    const queryParams = {params: getCustomerFromSearchParams(companyName, pagination)};

    return restApi.get(url, queryParams);
};

export const getCustomerToSearch = (companyName: string, pagination: PaginationValues): Promise<AxiosResponse> => {
    const url = `/customers/available`;
    const queryParams = {params: getCustomerFromSearchParams(companyName, pagination)};

    return restApi.get(url, queryParams);
};

export const getBidPendingUpdate = (): Promise<AxiosResponse> => {
    const url = `/bid/pending-update`;

    return restApi.get(url);
};

export const getBidByID = (id: string): Promise<AxiosResponse> => {
    const url = `/bid/${id}`;

    return restApi.get(url);
};
