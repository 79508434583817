import React, {Suspense, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import LoaderSpinner from 'components/ui/LoaderSpinner';

import useStateUserData from 'hooks/useStateUserData';

import {UserData} from '../core/entities/User/types';
import useIsLoggedInUser from '../hooks/useIsLoggedInUser';

const LoginComponent = React.lazy(() => import('../pages/Login'));
const BidsComponent = React.lazy(() => import('../pages/Bids'));
const Users = React.lazy(() => import('../pages/Users'));
const Customers = React.lazy(() => import('../pages/Customers'));
const NotFoundPage = React.lazy(() => import('../pages/NotFound'));
const Reports = React.lazy(() => import('../pages/Reports'));

const Routing: React.FC = () => {
    const user: UserData | undefined = useStateUserData();
    const isUserLoggedIn = useIsLoggedInUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isUserLoggedIn) {
            navigate('/');
        }
    }, [isUserLoggedIn]);

    return (
        <Suspense fallback={<LoaderSpinner />}>
            <Routes>
                <Route path="/" element={isUserLoggedIn ? <BidsComponent /> : <LoginComponent />} /> :
                <Route path="/bids" element={<BidsComponent />} />
                <Route path="/login" element={<LoginComponent />} />
                {user?.role === 'admin' ? <Route path="/customers" element={<Customers />} /> : null}
                {user?.role === 'admin' ? <Route path="/users" element={<Users />} /> : null}
                {user?.role === 'admin' ? <Route path="/reports" element={<Reports />} /> : null}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Suspense>
    );
};

export default Routing;
