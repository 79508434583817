import {DateTime} from 'luxon';

import {DAY_MONTH_YEAR_FORMAT, FULL_DATE_FORMAT, TIME_ZONE} from './constants';

export const getDateTimeFromISO = (date: string): DateTime => DateTime.fromISO(date, {locale: 'en-US'});

export const getStringDateInDayMonthYearFormat = (date: string | string[] | DateTime): string => {
    if (typeof date === 'string') {
        return getDateTimeFromISO(date).toFormat(DAY_MONTH_YEAR_FORMAT);
    }
    if (Array.isArray(date)) {
        return date.map((item) => getDateTimeFromISO(item).toFormat(DAY_MONTH_YEAR_FORMAT)).join(', ');
    }
    return date.toFormat(DAY_MONTH_YEAR_FORMAT);
};

export const getStringDateInFullDateFormat = (date: string): string => {
    return getDateTimeFromISO(date).toFormat(FULL_DATE_FORMAT);
};

export const convertToNewYorkISO = (date: DateTime): string | undefined => {
    if (!date) {
        return;
    }

    const dateFormat = date?.toFormat(FULL_DATE_FORMAT);
    const dateInNewYork = DateTime.fromFormat(dateFormat, FULL_DATE_FORMAT, {zone: TIME_ZONE});

    if (dateInNewYork.isValid) {
        return dateInNewYork.toISO();
    }
};
