import {Dispatch} from 'redux';

import {AppState, PaginationValues} from 'store/types';
import {showErrorModal, showSuccessModal} from 'store/actions/modalActions';

import {AddCustomerToBidFormValues, AddNoteToBidFormValues, CreateBidFormValues} from '../types';
import * as actionTypes from '../actionTypes';
import {isFileErrorResponse} from '../utils';
import {getIsFiltered} from '../selectors';
import transformAddCustomerInfoToRequest from '../mappers/transformAddCustomerInfoToRequest';

import {showCustomerModalsContainer, showFileErrorModal, showSameBidFoundModal} from './modalActions';
import * as requests from './requests';
import {searchBidsByPaginationPage} from './searchActions';
import {fetchFilteredBidsByPaginationPage} from './filterActions';

export const fetchAllBids = (pagination: PaginationValues) => async (dispatch: Dispatch<any>) => {
    try {
        const response = await requests.fetchBids(pagination);
        dispatch({
            type: actionTypes.LIST_BIDS_RECEIVED,
            payload: {
                items: response.items,
                pagination: response.pagination,
            },
        });
    } catch (error) {
        dispatch(showErrorModal());
        console.error('Error on search bids:', error);
    }
};

export const createBid = (bidData: CreateBidFormValues) => async (dispatch: Dispatch<any>) => {
    try {
        const response = await requests.postBid(bidData);
        dispatch(showCustomerModalsContainer({bidID: response.data.bid.id, isCallABidModal: false}));
    } catch (error) {
        if (isFileErrorResponse(error)) {
            dispatch(showFileErrorModal());
        } else {
            dispatch(showErrorModal());
        }
    }
};

export const setArchivedBid = (id: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: actionTypes.SET_ARCHIVED_BID,
        payload: {
            id,
        },
    });
};

export const createBidWithCheckOnExists = (bidData: CreateBidFormValues) => async (dispatch: Dispatch<any>) => {
    try {
        const response = await requests.searchSameBid(bidData);
        const {confirmedLines, bids: sameBids} = response.data;
        if (sameBids.length || confirmedLines.length) {
            dispatch(showSameBidFoundModal(bidData, sameBids, confirmedLines));
            return;
        }
        dispatch(createBid(bidData));
    } catch (error) {
        dispatch(showErrorModal());
        console.error('Error on search same bid before create new:', error);
    }
};

export const addNoteToBid = (values: AddNoteToBidFormValues) => async (dispatch: Dispatch<any>) => {
    try {
        const response = await requests.postBidNote(values);
        dispatch(showSuccessModal('note'));
        return response;
    } catch (error) {
        if (isFileErrorResponse(error)) {
            dispatch(showFileErrorModal());
        } else {
            dispatch(showErrorModal());
        }
        throw error;
    }
};

export const callBid = (id: string) => async (dispatch: Dispatch<any>) => {
    try {
        const response = await requests.callBid(id);
        dispatch(showCustomerModalsContainer({bidID: response.data.bid.id, isCallABidModal: true}));
    } catch (error) {
        dispatch(showErrorModal());
        console.error('Error on call bid: ', error);
    }
};

export const recallBid = (id: string) => async (dispatch: Dispatch<any>) => {
    try {
        await requests.recallBid(id);
    } catch (error) {
        dispatch(showErrorModal());
        console.error('Error on recall bid: ', error);
    }
};

export const fetchBidPagination = (nextPage: number) => (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const isFiltered = getIsFiltered(state);
    if (isFiltered) {
        dispatch(fetchFilteredBidsByPaginationPage(nextPage));
        return;
    }

    dispatch(searchBidsByPaginationPage(nextPage));
};

export const addCustomerToBid = (values: AddCustomerToBidFormValues, bidID: string) => {
    return async (dispatch: Dispatch<any>) => {
        const transformedParams = transformAddCustomerInfoToRequest(values);
        try {
            const response = await requests.postCustomerToBid(transformedParams, bidID);
            dispatch(showSuccessModal('customer info'));
            return response;
        } catch (error) {
            dispatch(showErrorModal());
            throw error;
        }
    };
};

export const fetchBidById = (id: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await requests.getBidByID(id);

            return response.data.bid;
        } catch (error) {
            dispatch(showErrorModal());
            console.error('Error on fetch bid by id:', error);
        }
    };
};
