import React, {useState} from 'react';
import {Modal} from 'antd';
import Form from 'antd/lib/form';
import {Field, Formik} from 'formik';

import NumberInput from 'components/ui/form/NumberInput';
import NoteFormTextArea from 'components/ui/form/NoteFormTextArea';

import {CreateBidFormValues} from 'pages/Bids/types';
import {
    MIN_STOPS_COUNT,
    MAX_STOPS_COUNT,
    MIN_RATE_AMOUNT,
    MAX_RATE_AMOUNT,
    rateStep,
    TEXT_FIELD_LENGTH,
} from 'pages/Bids/constants';
import useStateCreateBidModalValues from 'pages/Bids/hooks/useStateCreateBidModalValues';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import NoteFileUploader from '../../common/NoteFileUploader';
import TruckTypesSelect from '../../common/TruckTypesSelect';

import CityLineInputField from './components/CityLineInputField';
import ModalFooter from './components/ModalFooter';
import validation from './validation';
import styles from './createBid.module.scss';
import useCreateBidActions from './hooks/useCreateBidActions';
import {getInitialValuesForForm} from './utils';

interface OwnProps {
    data: {
        initialValues?: CreateBidFormValues;
        isOpenFromCopy?: boolean;
    };
    onClose(): void;
    onSubmit(values: CreateBidFormValues): void;
}

const getName = getTypeFieldNameFactory<CreateBidFormValues>();

const {Item} = Form;

const CreateBidModal = (props: OwnProps): JSX.Element => {
    const {data, onClose, onSubmit} = props;
    const initialValues = data?.initialValues;
    const isOpenFromCopy = data?.isOpenFromCopy;
    const createBidModalValues = useStateCreateBidModalValues();
    const [loading, setLoading] = useState<boolean>(false);
    const {clearValuesCreateBidModal, setValuesToCreateBidModal} = useCreateBidActions();

    const formInitialValues: CreateBidFormValues = getInitialValuesForForm(
        createBidModalValues,
        initialValues,
        isOpenFromCopy,
    );

    const handleClearValuesCreateBidModal = (): void => {
        if (!isOpenFromCopy) {
            clearValuesCreateBidModal();
        }
    };

    const handleSetCreateBidValuesToReducer = (values: CreateBidFormValues): void => {
        if (!isOpenFromCopy) {
            setValuesToCreateBidModal(values);
        }
    };

    const handleCancel = (resetForm: any): void => {
        handleClearValuesCreateBidModal();
        setLoading(false);
        onClose();
        resetForm();
    };

    const handleOk = (values: CreateBidFormValues): void => {
        handleClearValuesCreateBidModal();
        setLoading(true);
        onSubmit(values);
    };

    const handleBackgroundClick = (values: CreateBidFormValues): void => {
        handleSetCreateBidValuesToReducer(values);
        onClose();
    };

    return (
        <Formik
            initialValues={formInitialValues}
            onSubmit={handleOk}
            validationSchema={validation}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({handleSubmit, setFieldValue, values}) => {
                return (
                    <Modal
                        className={styles.modal}
                        visible={true}
                        closable={true}
                        title="Create a Bid"
                        onOk={() => handleSubmit()}
                        onCancel={() => handleBackgroundClick(values)}
                        footer={<ModalFooter loading={loading} handleCancel={handleCancel} isCancelButton={false} />}
                    >
                        <Form>
                            <Item label="Pickup:">
                                <div className="cityLine">
                                    <CityLineInputField
                                        name={getName('pickupCityLine')}
                                        setFieldValue={setFieldValue}
                                        validateSchema={validation}
                                    />
                                    <div className="cityLine__stops">
                                        <div className="cityLine__stops-count">
                                            <NumberInput
                                                name={getName('pickupAmount')}
                                                min={MIN_STOPS_COUNT}
                                                max={MAX_STOPS_COUNT}
                                                validateSchema={validation}
                                            />
                                        </div>
                                        <div className="cityLine__stops-desc">stop(s)</div>
                                    </div>
                                </div>
                            </Item>
                            <Item label="Delivery:">
                                <div className="cityLine">
                                    <CityLineInputField
                                        name={getName('deliveryCityLine')}
                                        setFieldValue={setFieldValue}
                                        validateSchema={validation}
                                    />
                                    <div className="cityLine__stops">
                                        <div className="cityLine__stops-count">
                                            <NumberInput
                                                name={getName('deliveryAmount')}
                                                min={MIN_STOPS_COUNT}
                                                max={MAX_STOPS_COUNT}
                                                validateSchema={validation}
                                            />
                                        </div>
                                        <div className="cityLine__stops-desc">stop(s)</div>
                                    </div>
                                </div>
                            </Item>
                            <div className="truck-details">
                                <div className="truck-details__rate">
                                    <NumberInput
                                        name={getName('rate')}
                                        label="Rate:"
                                        placeholder="$ *"
                                        min={MIN_RATE_AMOUNT}
                                        max={MAX_RATE_AMOUNT}
                                        maxLength={TEXT_FIELD_LENGTH}
                                        withDollarPrefix
                                        step={rateStep}
                                        validateSchema={validation}
                                    />
                                </div>
                                <div className="truck-details__truck-types">
                                    <TruckTypesSelect
                                        name={getName('truckType')}
                                        validateSchema={validation}
                                        placeholder="select truck type *"
                                    />
                                </div>
                            </div>

                            <Field
                                name={getName('note')}
                                type="text"
                                label="Note:"
                                placeholder="Enter note"
                                maxLength={TEXT_FIELD_LENGTH}
                                as={NoteFormTextArea}
                            />
                            <Field name={getName('files')} type="file" label="File:" as={NoteFileUploader} />
                        </Form>
                    </Modal>
                );
            }}
        </Formik>
    );
};

export default CreateBidModal;
