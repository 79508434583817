import React from 'react';
import {ObjectSchema} from 'yup';

import Select from 'components/ui/form/Select';

import {TRUCK_TYPES} from 'pages/Bids/constants';

const truckTypes = [
    {value: TRUCK_TYPES.sprinter.code, label: TRUCK_TYPES.sprinter.name},
    {value: TRUCK_TYPES.boxTruck.code, label: TRUCK_TYPES.boxTruck.name},
    {value: TRUCK_TYPES.straightTruck.code, label: TRUCK_TYPES.straightTruck.name},
    {value: TRUCK_TYPES.reeferSprinter.code, label: TRUCK_TYPES.reeferSprinter.name},
    {value: TRUCK_TYPES.reeferBoxTruck.code, label: TRUCK_TYPES.reeferBoxTruck.name},
    {value: TRUCK_TYPES.reeferStraightTruck.code, label: TRUCK_TYPES.reeferStraightTruck.name},
    {value: TRUCK_TYPES.tentedTruck.code, label: TRUCK_TYPES.tentedTruck.name},
    {value: TRUCK_TYPES.flatBed.code, label: TRUCK_TYPES.flatBed.name},
];

type OwnProps = {
    name: string;
    placeholder: string;
    validateSchema?: ObjectSchema<any>;
};

const TruckTypesSelect: React.FC<OwnProps> = (props) => {
    const {name, validateSchema, placeholder} = props;

    return (
        <Select
            name={name}
            placeholder={placeholder}
            required={true}
            validateSchema={validateSchema}
            options={truckTypes}
        />
    );
};

export default TruckTypesSelect;
