import React from 'react';
import {ObjectSchema} from 'yup';

import Select from 'components/ui/form/Select';

import {QUOTE_SOURCE} from 'pages/Bids/constants';

const quoteSource = [
    {value: QUOTE_SOURCE.dat.code, label: QUOTE_SOURCE.dat.name},
    {value: QUOTE_SOURCE.truckStop.code, label: QUOTE_SOURCE.truckStop.name},
    {value: QUOTE_SOURCE.brokersOwnLoadBoard.code, label: QUOTE_SOURCE.brokersOwnLoadBoard.name},
    {value: QUOTE_SOURCE.emailQuote.code, label: QUOTE_SOURCE.emailQuote.name},
    {value: QUOTE_SOURCE.incomingCall.code, label: QUOTE_SOURCE.incomingCall.name},
    {value: QUOTE_SOURCE.sylectusBoard.code, label: QUOTE_SOURCE.sylectusBoard.name},
    {value: QUOTE_SOURCE.another.code, label: QUOTE_SOURCE.another.name},
];

type OwnProps = {
    name: string;
    placeholder: string;
    label?: string;
    validateSchema?: ObjectSchema<any>;
};

const QuoteSourceSelect: React.FC<OwnProps> = (props) => {
    const {name, label, validateSchema, placeholder} = props;

    return (
        <Select
            name={name}
            placeholder={placeholder}
            label={label}
            validateSchema={validateSchema}
            options={quoteSource}
            required={false}
            style={{marginBottom: 0}}
        />
    );
};

export default QuoteSourceSelect;
