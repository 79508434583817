import {AxiosResponse} from 'axios';

import restApi from 'services/api';

export const fetchNotifications = (): Promise<AxiosResponse> => {
    const url = 'notifications';
    return restApi.get(url);
};

export const clearNotifications = (): Promise<AxiosResponse> => {
    const url = '/notifications';
    return restApi.delete(url);
};

export const removeNotification = (notificationId: string): Promise<AxiosResponse> => {
    const url = `/notification/${notificationId}`;
    return restApi.delete(url);
};
