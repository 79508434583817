import React from 'react';
import Col from 'antd/lib/grid/col';
import Text from 'antd/lib/typography/Text';

import styles from './logo.module.scss';

const Logo: React.FC = () => {
    return (
        <Col className={styles.logo} span={4}>
            <Text>Load Info</Text>
        </Col>
    );
};

export default Logo;
