import React, {useEffect, useRef} from 'react';
import {Alert, Modal, Button, Flex} from 'antd';
import {Formik, FormikProps} from 'formik';
import {FileDoneOutlined} from '@ant-design/icons/lib';

import {Bid} from 'core/entities/Bid/types';
import {CustomerFormValues} from 'core/entities/Customer/types';

import {AddCustomerToBidFormValues} from 'pages/Bids/types';
import {getBidByID} from 'pages/Bids/actions/requests';
import useBidActions from 'pages/Bids/hooks/useBidActions';

import validation from './components/CustomerInformationForm/validation';
import BidDetails from './components/BidDetails';
import styles from './addCustomerInfo.module.scss';
import CustomerInformationForm from './components/CustomerInformationForm';
import useCustomerOptions from './hooks/useCustomerOptions';

type OwnProps = {
    data: {bidID: string; isCallABidModal: boolean};
    onSubmit(values: AddCustomerToBidFormValues): void;
    onClose: () => void;
    openCustomerModal(value: boolean): any;
    customer?: CustomerFormValues;
};

const AddCustomerInfoModal: React.FC<OwnProps> = (props) => {
    const {
        data: {bidID, isCallABidModal},
        onSubmit,
        onClose,
        openCustomerModal,
        customer,
    } = props;
    const [modal, contextHolder] = Modal.useModal();
    const formikRef = useRef<FormikProps<AddCustomerToBidFormValues>>(null);

    const selectedCustomer = useCustomerOptions(customer);
    const {recallBid} = useBidActions();

    const [bid, setBid] = React.useState<Bid>();

    useEffect(() => {
        getBidByID(bidID).then((response) => {
            setBid(response.data);
        });
    }, []);

    const handleOk = (): void => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    };

    const handleClearFields = (): void => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };

    const handleCancelCall = (): void => {
        recallBid(bidID);
        onClose();
    };

    useEffect(() => {
        if (selectedCustomer?.length) {
            const customerOption = selectedCustomer[0];
            formikRef.current?.setFieldValue('customer', {
                id: customerOption.value,
                companyName: customerOption.label,
                mark: customerOption.mark,
            });
            formikRef.current?.setFieldValue('createdCustomer', {
                id: customerOption.value,
                companyName: customerOption.label,
                mark: customerOption.mark,
            });
        }
    }, [selectedCustomer]);

    const handlerSubmit = (values: AddCustomerToBidFormValues) => {
        onSubmit(values);
    };

    const messageText = isCallABidModal
        ? 'The bid has been successfully called. Please add customer information for more details. You cannot skip this step.'
        : 'The bid has been successfully created and published. Please add customer information for more details. You cannot skip this step.';

    const config = {
        title: isCallABidModal ? (
            <Flex align="center">
                <FileDoneOutlined style={{color: '#1677FF', fontSize: '20px', marginRight: '10px'}} /> Call a Bid
            </Flex>
        ) : (
            <Flex align="center">
                <FileDoneOutlined style={{color: '#1677FF', fontSize: '20px', marginRight: '10px'}} /> Add Customer
                Information
            </Flex>
        ),
        content: (
            <Formik
                innerRef={formikRef}
                initialValues={{}}
                onSubmit={handlerSubmit}
                validationSchema={validation}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {() => (
                    <>
                        <Alert message={messageText} type="success" />
                        {bid ? <BidDetails bid={bid} /> : null}
                        <CustomerInformationForm openCustomerModal={openCustomerModal} />
                    </>
                )}
            </Formik>
        ),
        footer: () => (
            <Flex justify="space-between">
                <Button onClick={handleClearFields}>Clear Fields</Button>
                <Flex>
                    {isCallABidModal ? (
                        <Button color="primary" onClick={handleCancelCall}>
                            Cancel Call
                        </Button>
                    ) : null}
                    <Button
                        type="primary"
                        onClick={() => {
                            handleOk();
                        }}
                    >
                        {isCallABidModal ? 'Add Information' : 'Add Customer'}
                    </Button>
                </Flex>
            </Flex>
        ),
    };

    const confirm = () => {
        modal.confirm({
            className: styles.modal,
            open: true,
            closable: false,
            icon: null,
            ...config,
        });
    };

    useEffect(() => {
        if (bid) {
            confirm();
        }
    }, [bid]);

    return <>{contextHolder}</>;
};

export default AddCustomerInfoModal;
