import React, {useEffect, useState} from 'react';
import {Select, Form} from 'antd';
import type {SelectProps} from 'antd';
import {ObjectSchema} from 'yup';
import {useField, FieldHookConfig} from 'formik';

import {fetchCustomerItems} from 'pages/Bids/actions/customerActions';
import {SelectOptionValue} from 'pages/Bids/types';

import validateField from 'utils/validateField';

export type SearchInputProps = {
    label?: string;
    placeholder?: string;
    required?: boolean;
    validateSchema?: ObjectSchema<any>;
} & FieldHookConfig<any> &
    SelectProps;

const {Item} = Form;

const SET_TIMEOUT = 300;

const SearchCustomerInput: React.FC<SearchInputProps> = (props) => {
    const {label, required, placeholder, validateSchema, ...fieldProps} = props;
    const [field, meta, helpers] = useField(fieldProps);
    const [data, setData] = useState<SelectProps['options']>([]);

    const handleSearch = (newValue: string) => {
        fetchCustomerItems(newValue, setData);
    };

    const handleChange = (selectedOption: SelectOptionValue) => {
        helpers.setValue(selectedOption);

        validateField({
            name: field.name,
            setError: helpers.setError,
            validateSchema,
            errors: meta.error,
            value: selectedOption,
        });
    };

    useEffect(() => {
        if (field.value?.id) {
            setTimeout(() => {
                handleChange({value: field.value.id, label: field.value.companyName, mark: field.value.mark});
            }, SET_TIMEOUT);
            handleSearch(field.value.companyName);
        }
    }, [field.value?.id]);

    return (
        <Item
            style={{width: '100%'}}
            label={label}
            required={required}
            help={meta.error}
            validateStatus={meta.error ? 'error' : ''}
        >
            <Select
                showSearch
                value={field.value}
                placeholder={placeholder}
                style={{width: '100%', fontWeight: 400}}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearch}
                onChange={(value, option) => handleChange(option as SelectOptionValue)}
                notFoundContent={null}
                options={data}
                popupMatchSelectWidth={300}
            />
        </Item>
    );
};

export default SearchCustomerInput;
