import {applyMiddleware, compose, createStore} from 'redux';
import logger from 'redux-logger';
import {thunk} from 'redux-thunk';
import {persistStore} from 'redux-persist';
import config from 'config';

import rootReducer from 'store/reducers';
import websocket from 'store/middlewares/websocket';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// Use the Redux DevTools extension compose function if available
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Define the middlewares
const getMiddlewares = () => {
    const middlewares = config.IS_DEV_MODE ? [thunk, logger, websocket] : [thunk, websocket];
    return applyMiddleware(...middlewares);
};

// Create the Redux store with the root reducer and middlewares
const store = createStore(rootReducer, composeEnhancers(getMiddlewares()));

// Create the persistor for the store
const persistor = persistStore(store);

export {store, persistor};
