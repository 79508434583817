import {UserData} from 'core/entities/User/types';

import {PaginationResponse} from 'store/types';

export const LIST_USERS_RECEIVED = 'USERS_PAGE_LIST_USERS_RECEIVED' as const;
export const LIST_USERS_SEARCH = 'LIST_USERS_SEARCH' as const;
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS' as const;
export const DELETE_USER = 'USERS_PAGE_DELETE_USER' as const;
export const CHANGE_USERS_PAGE = 'USERS_CHANGE_USERS_PAGE' as const;

type ListUsersReceived = {
    type: typeof LIST_USERS_RECEIVED;
    payload: {searchParams: string; users: UserData[]; pagination: PaginationResponse};
};

type ListUsersSearch = {
    type: typeof LIST_USERS_SEARCH;
    payload: {searchParams: string; users: UserData[]; pagination: PaginationResponse};
};

type ClearSearchParams = {
    type: typeof CLEAR_SEARCH_PARAMS;
};

type DeleteUser = {
    type: typeof DELETE_USER;
    payload: {id: string};
};

type ChangeUsersPage = {
    type: typeof CHANGE_USERS_PAGE;
    payload: {currentPage: number};
};

export type UsersActionTypes = ListUsersReceived | DeleteUser | ListUsersSearch | ChangeUsersPage | ClearSearchParams;
