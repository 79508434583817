import isEmpty from 'lodash/isEmpty';

import {Customer} from 'core/entities/Customer/types';

import {PaginationValues} from 'store/types';
import * as websocketTypes from 'store/middlewares/websocket/actionTypes';
import * as globalCustomerActions from 'store/actions/customersActionTypes';

import {getPaginationFromResponse} from 'utils/getPagination';

import * as types from '../actionTypes';

export type CustomersState = {
    customers: Customer[];
    customersPagination: PaginationValues;
    isSearch: boolean;
    isCustomerFromNotification: boolean;
    searchParams: string;
};

export const initialCustomersPagination: PaginationValues = {
    pageSize: 50,
    currentPage: 1,
    allItemsCount: 0,
    allPagesCount: 0,
};

export const defaultState: CustomersState = {
    customers: [],
    customersPagination: initialCustomersPagination,
    isSearch: false,
    isCustomerFromNotification: false,
    searchParams: '',
};

export default function customersReducer(
    // eslint-disable-next-line default-param-last
    state = defaultState,
    action:
        | types.CustomersActionTypes
        | websocketTypes.WebSocketActionTypes
        | globalCustomerActions.CustomersGlobalActionTypes,
): CustomersState {
    switch (action.type) {
        case types.LIST_CUSTOMERS_RECEIVED: {
            const {items, pagination} = action.payload;
            const customersPagination = isEmpty(pagination)
                ? initialCustomersPagination
                : getPaginationFromResponse(pagination);

            return {
                ...state,
                customers: items,
                customersPagination,
                isSearch: false,
                isCustomerFromNotification: false,
            };
        }

        case types.DELETE_CUSTOMER:
        case types.RESTORE_CUSTOMER:
        case types.UPDATE_CUSTOMER: {
            const updatedCustomer = action.payload;
            return {
                ...state,
                customers: state.customers.map((item) => (item.id === updatedCustomer.id ? updatedCustomer : item)),
            };
        }

        case types.CREATE_CUSTOMER: {
            const newCustomer = action.payload;
            return {
                ...state,
                customers: [newCustomer, ...state.customers],
                isCustomerFromNotification: false,
            };
        }

        case types.LIST_CUSTOMERS_SEARCH: {
            const {items, pagination, searchParams} = action.payload;
            const customersPagination = isEmpty(pagination)
                ? initialCustomersPagination
                : getPaginationFromResponse(pagination);

            return {
                ...state,
                customers: items,
                customersPagination,
                isSearch: true,
                isCustomerFromNotification: false,
                searchParams,
            };
        }

        case types.CLEAR_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: '',
            };

        case globalCustomerActions.RECEIVED_CUSTOMER_BY_ID: {
            return {
                ...state,
                isCustomerFromNotification: true,
                isSearch: false,
                customers: [action.payload],
                customersPagination: {
                    ...initialCustomersPagination,
                    allItemsCount: 1,
                    allPagesCount: 1,
                },
            };
        }

        default:
            return state;
    }
}
