import {Customer} from 'core/entities/Customer/types';

import {AppState, PaginationValues} from 'store/types';

export const getCustomerList = ({customersPage}: AppState): Customer[] => customersPage.customers;

export const getCustomersPagination = ({customersPage}: AppState): PaginationValues =>
    customersPage.customersPagination;

export const getIsCustomerFromNotification = ({customersPage}: AppState): boolean =>
    customersPage.isCustomerFromNotification;

export const getSearchParams = ({customersPage}: AppState): string => customersPage.searchParams;
