import {AxiosResponse} from 'axios';

import {UserData} from 'core/entities/User/types';

import restApi from 'services/api';

import {PaginationValues} from 'store/types';

const getPaginationValue = (pagination: PaginationValues): {page?: number; perPage?: number} => ({
    page: pagination.currentPage,
    perPage: pagination.pageSize,
});

export const searchUsers = ({
    search,
    pagination,
}: {
    search: string;
    pagination: PaginationValues;
}): Promise<AxiosResponse> => {
    const url = '/users';
    return restApi.get(url, {
        params: {
            search,
            ...getPaginationValue(pagination),
        },
    });
};

export const createUser = (user: UserData): Promise<AxiosResponse> => {
    const url = '/user';
    return restApi.post(url, user);
};

export const updateUser = (user: UserData): Promise<AxiosResponse> => {
    const url = `/user/${user.id}`;
    return restApi.put(url, user);
};

export const deleteUser = (id: string): Promise<AxiosResponse> => {
    const url = `/user/${id}`;
    return restApi.delete(url);
};

export const fetchUserList = (pagination: PaginationValues): Promise<AxiosResponse> => {
    const url = '/users';
    return restApi.get(url, {
        params: getPaginationValue(pagination),
    });
};

export const getUserById = (userID: string): Promise<AxiosResponse<{user: UserData}>> => {
    const url = `/users/${userID}`;
    return restApi.get(url);
};
