import React from 'react';
import {Card, Col, Row} from 'antd';
import {NodeIndexOutlined, EnvironmentOutlined} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import head from 'lodash/head';

import {Bid} from 'core/entities/Bid/types';

import {getCityLine} from 'pages/Bids/utils';
import {truckNames} from 'pages/Bids/constants';

type OwnProps = {
    bid: Bid;
};

const BidDetails: React.FC<OwnProps> = ({bid}) => {
    const pickupAmountText = bid?.pickupAmount > 1 ? ` (+${bid.pickupAmount - 1} stops)` : '';
    const deliveryAmountText = bid?.deliveryAmount > 1 ? ` (+${bid.deliveryAmount - 1} stops)` : '';

    const lastNote = head(bid?.notes);

    return (
        <Card
            title="Bid Details"
            bordered={false}
            style={{
                marginTop: '10px',
                backgroundColor: '#FAFAFA',
                fontSize: '12px',
                lineHeight: 1.7,
            }}
        >
            <Row>
                <Col span={6} style={{fontSize: '14px'}}>
                    <strong>
                        <NodeIndexOutlined style={{color: '#1677FF', fontSize: '16px', marginRight: '10px'}} />
                        Pick Up
                    </strong>
                </Col>
                <Col span={18}>
                    {bid?.pickupAddress ? getCityLine(bid.pickupAddress) : 'No address'}
                    {pickupAmountText}
                </Col>
            </Row>
            <Row>
                <Col span={6} style={{fontSize: '14px'}}>
                    <strong>
                        <EnvironmentOutlined style={{color: '#389E0D', fontSize: '16px', marginRight: '10px'}} />
                        Delivery
                    </strong>
                </Col>
                <Col span={18}>
                    {bid?.deliveryAddress ? getCityLine(bid.deliveryAddress) : 'No address'}
                    {deliveryAmountText}
                </Col>
            </Row>
            <Row>
                <Col span={6} style={{fontSize: '14px'}}>
                    Rate:
                </Col>
                <Col span={18}>
                    <Text strong>$ {bid?.rate}</Text>
                </Col>
            </Row>
            <Row>
                <Col span={6} style={{fontSize: '14px'}}>
                    Truck Type:
                </Col>
                <Col span={18}>{truckNames[bid?.truckType] || ''}</Col>
            </Row>
            {lastNote ? (
                <Row>
                    <Col span={6} style={{fontSize: '14px'}}>
                        Notes:
                    </Col>
                    <Col span={18}>
                        <Text>{lastNote.text}</Text>
                    </Col>
                </Row>
            ) : null}
        </Card>
    );
};

export default BidDetails;
