import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import {Bid, Note} from 'core/entities/Bid/types';
import {ConfirmedLine} from 'core/entities/ConfirmedLine/types';

import {initialCreateBidValues} from 'pages/Bids/data';

import * as websocketTypes from 'store/middlewares/websocket/actionTypes';
import {PaginationValues} from 'store/types';
import * as globalBidsActions from 'store/actions/bidsActionTypes';

import getFilePath from 'utils/getFilePath';

import {SearchParamsValues, BidImages, Filters, CreateBidFormValues} from '../types';
import * as types from '../actionTypes';
import {isImageFile} from '../utils';

import {
    decrementPagination,
    incrementPagination,
    isBidCanBeAdded,
    isCanNotChangeStateFromWebsocketResponse,
    isNeedAddConfirmedLine,
} from './utils';
import {isBidNotInSearchParams} from './utils/search';
import {isBidNotInFilterParams} from './utils/filter';
import {initialBidImages, initialBidsPagination, initialConfirmedLinesPagination, initialSearchParams} from './data';

export interface BidsState {
    bids: Bid[];
    bidsPagination: PaginationValues;
    confirmedLines: ConfirmedLine[];
    confirmedLinesPagination: PaginationValues;
    searchParams: SearchParamsValues;
    isSearch: boolean;
    filtersParams: Filters;
    isFiltered: boolean;
    bidImages: BidImages;
    createBidModal: CreateBidFormValues;
    isBidFromNotification: boolean;
}

const defaultState: BidsState = {
    bids: [],
    bidsPagination: initialBidsPagination,
    confirmedLines: [],
    confirmedLinesPagination: initialConfirmedLinesPagination,
    searchParams: initialSearchParams,
    isSearch: false,
    filtersParams: {},
    isFiltered: false,
    bidImages: initialBidImages,
    createBidModal: initialCreateBidValues,
    isBidFromNotification: false,
};

export default function bidsReducer(
    // eslint-disable-next-line default-param-last
    state = defaultState,
    action: types.BidsActionTypes | websocketTypes.WebSocketActionTypes | globalBidsActions.BidsGlobalActionTypes,
): BidsState {
    const {
        bidsPagination: {currentPage: pagePagination},
    } = state;
    switch (action.type) {
        case types.LIST_CONFIRMED_LINES_RECEIVED: {
            const {items, pagination} = action.payload;
            const confirmedLinesPagination = isEmpty(pagination) ? initialConfirmedLinesPagination : pagination;
            return {
                ...state,
                confirmedLines: items,
                confirmedLinesPagination,
            };
        }

        case types.LIST_BIDS_RECEIVED: {
            const {items, pagination} = action.payload;
            const bidsPagination = isEmpty(pagination) ? initialBidsPagination : pagination;
            return {
                ...state,
                bids: items,
                bidsPagination,
                isSearch: false,
                isFiltered: false,
                isBidFromNotification: false,
            };
        }

        case types.SEARCH_PARAMS_CHANGED: {
            const {params} = action.payload;
            return {
                ...state,
                isSearch: Boolean(params.searchValue),
                isFiltered: false,
                isBidFromNotification: false,
                filtersParams: {},
                searchParams: params,
            };
        }

        case types.SET_ARCHIVED_BID: {
            const {id} = action.payload;

            const bids = state.bids.map((bid: Bid): Bid => {
                if (bid.id === id) {
                    bid.isArchived = true;
                }
                return bid;
            });
            return {
                ...state,
                bids: sortBy(bids, [(bid) => bid.updatedAt || bid.createdAt]).reverse(),
            };
        }

        case types.CHANGE_BIDS_PAGE: {
            const {currentPage} = action.payload;

            return {
                ...state,
                bidsPagination: {
                    ...state.bidsPagination,
                    currentPage,
                },
            };
        }

        case types.CHANGE_CONFIRMED_LINES_PAGE: {
            const {currentPage} = action.payload;

            return {
                ...state,
                confirmedLinesPagination: {
                    ...state.confirmedLinesPagination,
                    currentPage,
                },
            };
        }

        case types.CLEAR_BIDS_GALLERY: {
            return {
                ...state,
                bidImages: {
                    images: [],
                    currentPictureIndex: undefined,
                },
            };
        }

        case types.OPEN_BIDS_GALLERY: {
            const {bidId, noteId} = action.payload;

            const bid = state.bids.find(({id}: Bid): boolean => bidId === id);

            let currentPictureIndex;
            const images =
                bid?.notes?.reduce((array: string[], note: Note) => {
                    const {attachment} = note;
                    if (attachment && isImageFile(attachment)) {
                        const url = getFilePath(attachment);
                        array.push(url);
                        if (noteId === note.id) {
                            currentPictureIndex = array.indexOf(url);
                        }
                    }
                    return array;
                }, []) || [];

            return {
                ...state,
                bidImages: {
                    images,
                    currentPictureIndex,
                },
            };
        }

        case types.FILTERS_PARAMS_CHANGED: {
            const {filtersParams} = action.payload;
            return {
                ...state,
                isFiltered: true,
                isSearch: false,
                isBidFromNotification: false,
                searchParams: initialSearchParams,
                filtersParams,
            };
        }

        case types.CLEAR_FILTERS_PARAMS: {
            return {
                ...state,
                isFiltered: false,
                filtersParams: {},
            };
        }

        case types.ADD_CONFIRMED_LINE: {
            const {allItemsCount, allPagesCount} = incrementPagination(state);

            if (!isNeedAddConfirmedLine(state.confirmedLinesPagination)) {
                return {
                    ...state,
                    confirmedLinesPagination: {
                        ...state.confirmedLinesPagination,
                        allItemsCount,
                        allPagesCount,
                    },
                };
            }

            const {item} = action.payload;

            const confirmedLines = [item, ...state.confirmedLines];

            return {
                ...state,
                confirmedLines,
                confirmedLinesPagination: {
                    ...state.confirmedLinesPagination,
                    allItemsCount,
                    allPagesCount,
                },
            };
        }

        case types.UPDATE_CONFIRMED_LINE: {
            const {item} = action.payload;

            const confirmedLines = state.confirmedLines.map((confirmedLine: ConfirmedLine): ConfirmedLine => {
                if (confirmedLine.id === item.id) {
                    return item;
                }

                return confirmedLine;
            });

            return {
                ...state,
                confirmedLines,
            };
        }

        case types.REMOVE_CONFIRMED_LINE: {
            const {confirmedLineId} = action.payload;

            const {allItemsCount, allPagesCount} = decrementPagination(state);

            const confirmedLines = state.confirmedLines.filter(
                ({id}: ConfirmedLine): boolean => id !== confirmedLineId,
            );

            return {
                ...state,
                confirmedLines,
                confirmedLinesPagination: {
                    ...state.confirmedLinesPagination,
                    allItemsCount,
                    allPagesCount,
                },
            };
        }

        case types.SET_VALUES_TO_CREATE_BID_MODAL: {
            return {
                ...state,
                createBidModal: action.payload,
            };
        }

        case types.CLEAR_VALUES_CREATE_BID_MODAL: {
            return {
                ...state,
                createBidModal: initialCreateBidValues,
            };
        }

        case globalBidsActions.RECEIVED_BID_BY_ID: {
            return {
                ...state,
                isBidFromNotification: true,
                isFiltered: false,
                isSearch: false,
                bids: [action.payload],
                bidsPagination: {
                    ...initialBidsPagination,
                    allItemsCount: 1,
                    allPagesCount: 1,
                },
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_CREATED_BID: {
            const {
                bidsPagination: {allItemsCount},
            } = state;

            const {newBid, currentUser} = action.payload;

            if (
                (state.isSearch && isBidNotInSearchParams(state.searchParams, newBid)) ||
                (state.isFiltered && isBidNotInFilterParams(state.filtersParams, newBid, currentUser)) ||
                state.isBidFromNotification
            ) {
                return state;
            }

            if (!isBidCanBeAdded(pagePagination)) {
                return {
                    ...state,
                    bidsPagination: {
                        ...state.bidsPagination,
                        allItemsCount: allItemsCount ? allItemsCount + 1 : 1,
                    },
                };
            }
            // temp for debug return current state
            return {
                ...state,
                bids: [newBid, ...state.bids],
                bidsPagination: {
                    ...state.bidsPagination,
                    allItemsCount: allItemsCount ? allItemsCount + 1 : 1,
                },
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_ADD_CUSTOMER_INFO_TO_BID: {
            const {bidID, customerInfo} = action.payload;

            const updatedBids = state.bids.map((bid: Bid) => {
                if (bid.id === bidID) {
                    return {
                        ...bid,
                        customerInfo,
                    };
                }
                return bid;
            });

            return {
                ...state,
                bids: updatedBids,
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_CREATED_BID_NOTE: {
            const {newBid, currentUser} = action.payload;

            if (isCanNotChangeStateFromWebsocketResponse(state, newBid, currentUser, pagePagination)) {
                return state;
            }

            const updatedBids = state.bids.map((bid: Bid) => {
                if (bid.id === newBid.id) {
                    return newBid;
                }
                return bid;
            });

            return {
                ...state,
                bids: updatedBids,
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_UPDATE_BID_NOTE: {
            const {props} = action.payload;

            const updatedBids = state.bids.map((bid: Bid) => {
                if (bid.id === props.bidID) {
                    return {
                        ...bid,
                        notes: bid.notes?.map((note: Note) => {
                            if (note.id === props.noteID) {
                                return {
                                    ...note,
                                    payload: {
                                        customerName: props.customerInfo.name,
                                        quoteSource: props.customerInfo.quoteSource,
                                        mark: props.customerInfo.mark,
                                    },
                                };
                            }
                            return note;
                        }),
                    };
                }
                return bid;
            }) as Bid[];

            return {
                ...state,
                bids: updatedBids,
            };
        }

        case websocketTypes.WEB_SOCKET_RECEIVED_CALL_BID: {
            const {
                payload: {callBid, currentUser},
            } = action;
            if (isCanNotChangeStateFromWebsocketResponse(state, callBid, currentUser, pagePagination)) {
                return state;
            }

            const updatedBids = state.bids.map((bid: Bid) => {
                if (bid.id === callBid.id) {
                    return callBid;
                }
                return bid;
            });

            return {
                ...state,
                bids: updatedBids,
            };
        }

        default:
            return state;
    }
}
