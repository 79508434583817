import React from 'react';
import {useField} from 'formik';
import Item from 'antd/lib/form/FormItem';
import InputNumber from 'antd/lib/input-number';
import {ObjectSchema} from 'yup';

import validateField from 'utils/validateField';

export type NumberInputProps = {
    name: string;
    min?: number;
    max?: number;
    maxLength?: number;
    label?: string;
    placeholder?: string;
    withDollarPrefix?: boolean;
    required?: boolean;
    size?: 'large' | 'middle' | 'small';
    step?: number;
    validateSchema?: ObjectSchema<any>;
};

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
    const {
        min,
        max,
        maxLength,
        label,
        placeholder,
        validateSchema,
        required = false,
        withDollarPrefix = false,
        size = 'middle',
        step = 1,
    } = props;
    const [field, meta, helpers] = useField(props);
    const handleOnChange = (value: any): void => {
        const updatedValue = (!value || value === '') && withDollarPrefix ? undefined : value;
        helpers.setValue(updatedValue);

        validateField({
            name: field.name,
            value: updatedValue,
            setError: helpers.setError,
            validateSchema,
            errors: meta.error,
        });
    };
    const formatter = (value: any): any => (withDollarPrefix && value ? `$ ${value}` : value);
    const parser = (value: any): any => value.replace(/\$\s?|(,*)/g, '');

    return (
        <Item label={label} required={required} help={meta.error} validateStatus={meta.error ? 'error' : ''}>
            <InputNumber
                {...field}
                onChange={handleOnChange}
                formatter={formatter}
                parser={parser}
                maxLength={maxLength}
                min={min}
                max={max}
                placeholder={placeholder}
                size={size}
                step={step}
            />
        </Item>
    );
};

export default NumberInput;
