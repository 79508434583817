import {convertToNewYorkISO} from 'utils/date';

import {Filters} from '../types';

export const transformFilterParamsToRequest = (filters: any): Filters => {
    return {
        ...filters,
        createdAtFrom: convertToNewYorkISO(filters.createdAtFrom),
        createdAtTo: convertToNewYorkISO(filters.createdAtTo),
        updatedAtFrom: convertToNewYorkISO(filters.updatedAtFrom),
        updatedAtTo: convertToNewYorkISO(filters.updatedAtTo),
    };
};
