import React from 'react';
import {Field} from 'formik';
import {ObjectSchema} from 'yup';

import AddressInput from 'pages/Bids/components/common/AddressInput';

interface OwnProps {
    name: string;
    setFieldValue(fieldName: string, newValue: string): void;
    validateSchema?: ObjectSchema<any>;
}

const CityLineInputField = (props: OwnProps): JSX.Element => {
    const {name, setFieldValue} = props;
    const setStateToUpperCase = (event: any): void => {
        const currentValue = event?.target?.value;
        if (!currentValue) {
            return;
        }
        const [city, state] = currentValue.split(',');
        if (!city || !state) {
            return;
        }
        const stateInUpperCase = state.toUpperCase();
        const formattedValue = [city, stateInUpperCase].join(',');
        setFieldValue(name, formattedValue);
    };

    return (
        <Field
            name={name}
            type="text"
            placeholder="enter city and state"
            as={AddressInput}
            onBlur={setStateToUpperCase}
            validateSchema={props.validateSchema}
        />
    );
};

export default CityLineInputField;
