import React, {ChangeEvent} from 'react';
import {FieldInputProps, useField} from 'formik';
import Input from 'antd/lib/input';
import Item from 'antd/lib/form/FormItem';
import {FormikHandlers} from 'formik/dist/types';
import {ObjectSchema} from 'yup';

import {spaceKeyCode} from 'constants/index';

import validateField from 'utils/validateField';

export interface FormInputProps extends FieldInputProps<string> {
    prefix?: React.ReactNode;
    placeholder: string;
    type?: string | undefined;
    label?: string | undefined;
    required: boolean;
    maxLength?: number | undefined;
    isNeedSpaceIgnore: boolean;
    metaText?: string;
    className?: string;
    isNeedIgnoreSpecialSymbols?: boolean;
    onChange: FormikHandlers['handleChange'];
    validateSchema?: ObjectSchema<any>;
}

const FormInput: React.FC<FormInputProps> = ({
    prefix,
    placeholder,
    type,
    label,
    required = true,
    maxLength,
    isNeedSpaceIgnore = false,
    metaText,
    className,
    onBlur,
    onChange,
    isNeedIgnoreSpecialSymbols,
    validateSchema,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);
    const spaceIgnore = (event: any): void => {
        if (event.keyCode === spaceKeyCode && (isNeedSpaceIgnore || field.value.length === 0)) {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
    };
    const handleBlur = (focusEvent: any): void => {
        field.onBlur(focusEvent);
        if (onBlur) {
            onBlur(focusEvent);
        }
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const {value} = event.target;
        const reg = /^[\d\w ]*?$/;
        if (!isNeedIgnoreSpecialSymbols || reg.test(value as string) || value === '') {
            onChange(event);

            validateField({name: field.name, value, setError: helpers.setError, validateSchema, errors: meta.error});
        }
    };

    return (
        <Item
            label={label}
            className={className}
            extra={metaText}
            required={required}
            help={meta.error}
            validateStatus={meta.error ? 'error' : ''}
        >
            <Input
                {...field}
                onBlur={handleBlur}
                onKeyDown={spaceIgnore}
                maxLength={maxLength}
                prefix={prefix}
                type={type || 'text'}
                placeholder={placeholder}
                onChange={handleChange}
            />
        </Item>
    );
};

export default FormInput;
