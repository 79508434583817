import {TruckType, QuoteSourceType} from '../types';

export const modalNames = {
    addBidNoteModal: 'ADD_BID_NOTE_MODAL',
    createBidModal: 'CREATE_A_BID_MODAL',
    sameBidFoundModal: 'SAME_BID_FOUND_BID_MODAL',
    customerModalsContainer: 'CUSTOMER_MODALS_CONTAINER',
};

export const bidListFrom = 150;
export const bidListTo = 150;
export const bidListRate = 80;
export const bidListTruckType = 120;
export const bidListQuoteSource = 150;
export const bidListCustomer = 150;
export const bidListDate = 120;
export const bidListCalls = 150;
export const bidListCompany = 150;
export const bidListUser = 150;

export const confirmedLineFrom = 250;
export const confirmedLineTo = 250;
export const confirmedLineTruckType = 180;
export const confirmedLineCompany = 150;
export const confirmedLineSource = 130;
export const confirmedLineCustomer = 220;
export const confirmedLineRate = 120;
export const confirmedLineDetails = 'auto';
export const confirmedLineAction = 193;

export const confirmedLinePageSize = 3;

export const callBidTooltip = `can't call own bid`;
export const calledBidTooltip = `bid was called by you`;

export const MIN_STOPS_COUNT = 1;
export const MAX_STOPS_COUNT = 99;

export const MIN_RATE_AMOUNT = 1;
export const MAX_RATE_AMOUNT = 99999;

export const TEXT_FIELD_LENGTH = 144;

export const rateStep = 50;

export const deletedUserPlaceholder = 'user deleted';

export const fileErrorResponse = 'Bad file type';
export const largeFileErrorResponse = 'File too large';
export const statusCodeLargeFile = 413;
export const fileErrorMessage = 'The file could not be uploaded, please try again later.';
export const fileErrorTitle = 'File Error';

export const LOCATION_REQUIRED_MESSAGE = 'Please enter location';
export const LOCATION_REQUIRED_CITY = 'enter correct location city';
export const LOCATION_REQUIRED_STATE = 'enter correct location state';
export const LETTERS_REQUIRED_MESSAGE = 'location must contain letters';
export const LETTERS = /[a-zA-Z]+/;

export const TRUCK_TYPES = {
    sprinter: {code: 'SPRINTER', name: 'Sprinter'},
    boxTruck: {code: 'BOX_TRUCK', name: 'Box Truck'},
    straightTruck: {code: 'STRAIGHT_TRUCK', name: 'Straight Truck'},
    reeferSprinter: {code: 'REEFER_SPRINTER', name: 'Reefer Sprinter'},
    reeferBoxTruck: {code: 'REEFER_BOX_TRUCK', name: 'Reefer Box Truck'},
    reeferStraightTruck: {code: 'REEFER_STRAIGHT_TRUCK', name: 'Reefer Straight Truck'},

    tentedTruck: {code: 'TENTED_TRUCK', name: 'Tented Truck'},
    flatBed: {code: 'FLAT_BED', name: 'FlatBed'},
} as const;

export const QUOTE_SOURCE = {
    dat: {code: 'DAT', name: 'DAT'},
    truckStop: {code: 'TRUCK_STOP', name: 'TruckStop'},
    brokersOwnLoadBoard: {code: 'BROKERS_OWN_LOAD_BOARD', name: "Broker's own load board"},
    emailQuote: {code: 'EMAIL_QUOTE', name: 'Email quote'},
    incomingCall: {code: 'INCOMING_CALL', name: 'Incoming Call'},
    sylectusBoard: {code: 'SYLECTUS_BOARD', name: 'Sylectus Board'},
    another: {code: 'ANOTHER', name: 'Another'},
} as const;

export const truckNames: Record<TruckType, string> = {
    [TRUCK_TYPES.sprinter.code]: TRUCK_TYPES.sprinter.name,
    [TRUCK_TYPES.boxTruck.code]: TRUCK_TYPES.boxTruck.name,
    [TRUCK_TYPES.straightTruck.code]: TRUCK_TYPES.straightTruck.name,
    [TRUCK_TYPES.reeferSprinter.code]: TRUCK_TYPES.reeferSprinter.name,
    [TRUCK_TYPES.reeferBoxTruck.code]: TRUCK_TYPES.reeferBoxTruck.name,
    [TRUCK_TYPES.reeferStraightTruck.code]: TRUCK_TYPES.reeferStraightTruck.name,
    [TRUCK_TYPES.tentedTruck.code]: TRUCK_TYPES.tentedTruck.name,
    [TRUCK_TYPES.flatBed.code]: TRUCK_TYPES.flatBed.name,
};

export const quoteSourceNames: Record<QuoteSourceType, string> = {
    [QUOTE_SOURCE.dat.code]: QUOTE_SOURCE.dat.name,
    [QUOTE_SOURCE.truckStop.code]: QUOTE_SOURCE.truckStop.name,
    [QUOTE_SOURCE.brokersOwnLoadBoard.code]: QUOTE_SOURCE.brokersOwnLoadBoard.name,
    [QUOTE_SOURCE.emailQuote.code]: QUOTE_SOURCE.emailQuote.name,
    [QUOTE_SOURCE.incomingCall.code]: QUOTE_SOURCE.incomingCall.name,
    [QUOTE_SOURCE.sylectusBoard.code]: QUOTE_SOURCE.sylectusBoard.name,
    [QUOTE_SOURCE.another.code]: QUOTE_SOURCE.another.name,
};
