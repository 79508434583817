import {AxiosResponse} from 'axios';

import restApi from '../../services/api';

export function fetchBidById(bidId: string): Promise<AxiosResponse> {
    const url = `/bid/${bidId}`;
    return restApi.get(url);
}

export function fetchCustomerByID(customerId: string): Promise<AxiosResponse> {
    const url = `/customers/${customerId}`;
    return restApi.get(url);
}
