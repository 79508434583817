import {AppSettings} from 'context/appSettings';

import {twoHours} from 'constants/index';

import restApi from 'services/api';

export const fetchAppSettings = (): Promise<AppSettings> => {
    const url = '/app-settings';
    return restApi
        .get(url)
        .then((response) => response.data)
        .catch((error) => {
            console.error('Error on fetch expire duration:', error);
            return {bidExpireDuration: twoHours};
        });
};
