import {CustomerFormValues, CustomerParams} from 'core/entities/Customer/types';

import {parseStringToCityStateZipCountry} from 'pages/Customers/utils';

export default function (params: CustomerFormValues): CustomerParams {
    const addressProps = parseStringToCityStateZipCountry(params.location);

    return {
        companyName: params.companyName,
        status: params.status,
        mark: params.mark,
        mcNumber: params.mcNumber,
        dotNumber: params.dotNumber,
        billingAddress: {
            addressLine: params.address,
            ...addressProps,
        },
        note: params.note,
    };
}
